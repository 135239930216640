import { useMemo } from 'react';
import * as yup from 'yup';

import { useI18next } from '@trader/services';
import { EBrand } from '@trader/types';
import { appConfigUtils } from '@trader/utils';

const minCharacters = 8;
const maxCharacters = 15;

export const useChangePasswordSchema = () => {
  const { translate, currentLng } = useI18next();

  // TODO: (ok) clarify about adding pass regex to login password
  const regex = useMemo(
    () =>
      appConfigUtils.getCurrentBrand() === EBrand.TradeItaly
        ? '(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*&])[A-Za-z\\d@$!%*&]{8,15}$'
        : '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*&])[A-Za-z\\d@$!%*&]{8,15}$',
    []
  );
  const passwordErrorMessage = useMemo(
    () =>
      appConfigUtils.getCurrentBrand() === EBrand.TradeItaly
        ? 'COMMON.ERRORS.PASSWORDS_REGEX_LOWER_UPPER_SYMBOLS'
        : 'COMMON.ERRORS.PASSWORDS_REGEX_LOWER_UPPER_DIGIT_SYMBOLS',
    [currentLng]
  );

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          password: yup
            .string()
            .required(translate('COMMON.ERRORS.REQUIRED_PASSWORD')),
          proposedPassword: yup
            .string()
            .min(minCharacters, translate('COMMON.ERRORS.MIN_PASSWORD_LENGTH'))
            .max(maxCharacters, translate('COMMON.ERRORS.MAX_PASSWORD_LENGTH'))
            .matches(new RegExp(regex), translate(passwordErrorMessage))
            .required(translate('COMMON.ERRORS.REQUIRED_PASSWORD')),
          matchProposedPassword: yup
            .string()
            .oneOf(
              [yup.ref('proposedPassword'), ''],
              translate('COMMON.ERRORS.PASSWORDS_DO_NOT_MATCH')
            )
            .required(translate('COMMON.ERRORS.REQUIRED_PASSWORD')),
        })
        .required(),
    [currentLng]
  );

  return { validationSchema };
};
