import styled, { css } from 'styled-components';

import { layoutChildrenRadius, layoutMargin } from '@trader/constants';
import { IconButton, Wrapper } from '@trader/components';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)<{ $isFullScreen?: boolean }>`
  ${({ $isFullScreen, theme }) => css`
    ${conditionApply(
      [$isFullScreen],
      css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: ${theme.zIndex.modal + 2};
        background: ${theme.palette.background.paper};
        border-radius: ${layoutChildrenRadius}px;
      `
    )};
  `};
`;

export const ToggleFullScreen = styled(IconButton)`
  ${({ theme }) => css`
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: ${theme.zIndex.modal};

    svg {
      width: 16px;
    }
  `};
`;

export const Content = styled(Wrapper)`
  ${() => css`
    padding-top: ${layoutMargin}px;
    width: 100%;
    overflow: hidden;
    position: relative;

    .progress {
      position: absolute;
      top: 18px;
      right: 55px;
      min-height: 20px;
      width: 20px;
      height: 20px;
    }
  `};
`;
