import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { useMst } from '@trader/store';
import { ITransferHistoryBE, ITransfersHistoryBE } from '@trader/api';
import { dateFormats } from '@trader/utils';
import {
  EmptyData,
  IHeadCell,
  IRenderRowItemsCallBacks,
  Progress,
  ScrollingList,
} from '@trader/components';
import { useI18next } from '@trader/services';

interface IState {
  isFetchingMore: boolean;
  isLoading: boolean;
  transfers: ITransfersHistoryBE['transfers'];
  totalCount: number;
}

export const TransfersHistory: React.FC = observer(() => {
  const store = useMst();
  const { currentLng, translate } = useI18next();
  const [state, setState] = useState<IState>({
    isFetchingMore: false,
    isLoading: false,
    transfers: [],
    totalCount: 0,
  });

  useEffect(() => {
    (async function () {
      const response = await store.user.getTransfersHistoryAsync.run();
      if (response) {
        setState(prev => ({
          ...prev,
          totalCount: response.totalCount,
          isLoading: false,
          transfers: response.transfers,
        }));
      }
    })();
  }, []);

  const formatDate = 1000;

  const fetchMoreHistory = async (pageNumber: number) => {
    setState(prev => ({ ...prev, isFetchingMore: true }));
    const response = await store.user.getTransfersHistoryAsync.run({
      pageNumber,
    });
    if (response) {
      setState(prev => ({
        ...prev,
        transfers: [...state.transfers, ...response.transfers],
      }));
    }
  };

  const renderRowItemsCallBacks: IRenderRowItemsCallBacks<ITransferHistoryBE> =
    useMemo(
      () => ({
        transferId: item => item.value,
        currencySymbol: item => item.value,
        value: item => item.value,
        status: item => item.value,
        transferType: item => item.value,
        'date.seconds': item =>
          dateFormats(
            new Date((item?.value as number) * formatDate),
            'Mm dd, yyyy',
            currentLng
          ),
      }),
      []
    );

  const cells: Array<IHeadCell> = [
    {
      id: 'transferId',
      label: translate('COMMON.LABELS.TRANSFER'),
      minWidth: 60,
      align: 'start',
    },
    {
      id: 'currencySymbol',
      label: translate('COMMON.LABELS.SYMBOL'),
      minWidth: 40,
      align: 'center',
    },
    {
      id: 'value',
      label: translate('COMMON.LABELS.AMOUNT'),
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 120,
      align: 'center',
    },
    {
      id: 'transferType',
      label: translate('COMMON.LABELS.TYPE'),
      minWidth: 70,
      align: 'start',
    },
    {
      id: 'date.seconds',
      label: translate('COMMON.LABELS.DATE'),
      minWidth: 120,
      align: 'start',
    },
  ];

  if (!state.isFetchingMore && store.user.getTransfersHistoryAsync.inProgress) {
    return <Progress />;
  }
  if (!state.transfers.length && !state.isLoading) {
    return <EmptyData title={translate('COMMON.LABELS.NO_TRANSFERS')} />;
  }

  return (
    <ScrollingList<ITransferHistoryBE>
      rows={state.transfers}
      headCells={cells}
      renderRowItemsCallBacks={renderRowItemsCallBacks}
      totalCount={state.totalCount}
      fetchMore={fetchMoreHistory}
    />
  );
});
