import * as yup from 'yup';

import {
  highMultiplier,
  lowMultiplier,
  multiplierStep,
} from '@trader/constants';
import { convertFromOption } from '@trader/utils';
import { TInstrumentEntity, useMst } from '@trader/store';

export const useBackTestingFormValidation = () => {
  const store = useMst();

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    convertFromOption<string>(backTesting.getSymbolOption())
  );

  return yup.object().shape({
    symbol: yup
      .array()
      .test('check-symbol', 'Required', value => {
        return !!value?.length;
      })
      .required('Required'),
    lowestMultiplier: yup
      .number()
      .typeError('Required')
      .min(lowMultiplier, `Min ${lowMultiplier}`)
      .max(highMultiplier, `Max ${highMultiplier}`)
      .test('check-multiplier', 'Required', value => {
        return !!value || !!Number(value);
      }),
    highestMultiplier: yup
      .number()
      .typeError('Required')
      .min(lowMultiplier, `Min ${lowMultiplier}`)
      .max(highMultiplier, `Max ${highMultiplier}`)
      .when(['lowestMultiplier'], ([lowestMultiplier], schema) => {
        return schema.test(
          'check-lowestMultiplier',
          `Higher than ${lowestMultiplier}`,
          value => {
            if (!value) {
              return false;
            }

            if (lowestMultiplier) {
              return value >= lowestMultiplier;
            }
          }
        );
      }),
    multiplierStep: yup
      .number()
      .typeError('Required')
      .min(multiplierStep, `Min ${multiplierStep}`)
      .max(multiplierStep, `Max ${highMultiplier}`)
      .test('check-multiplier', 'Required', value => {
        return !!value || !!Number(value);
      }),
    from: yup.date().typeError('Invalid date').required('Required'),
    to: yup
      .date()
      .typeError('Invalid date')
      .when(['from'], ([from], schema) => {
        return schema.test('dates-diff', 'Invalid date', value => {
          if (!value) {
            return false;
          }

          if (from) {
            const fromTimestamp = new Date(from).getTime();
            const toTimestamp = new Date(value).getTime();
            return toTimestamp > fromTimestamp;
          }
        });
      })
      .required('Required'),
    quantity: yup
      .number()
      .typeError('Required')
      .min(instrument?.minOrderSize, `Higher than ${instrument?.minOrderSize}`)
      .max(instrument?.maxOrderSize, `Lower than ${instrument?.maxOrderSize}`)
      .required('Required'),
  });
};
