import { useMst } from '@trader/store';
import { useTheme } from '@mui/material';
import { TPlaceOrderSide } from '@trader/types';

export const useGetAdapterDiff = (placedType: 'Order' | 'Position') => {
  const store = useMst();
  const theme = useTheme();

  const strips = Array.from(store.pages.muliBands.strips.values());

  const manuallyTitle = `${placedType} has been placed manually`;
  const strategyTitle = `${placedType} has been placed as part of the strategy`;

  const isManuallyPlaced = (id: string) => {
    const key = placedType === 'Order' ? 'orderId' : 'positionId';
    return !strips.find(s => s[key] === Number(id));
  };

  const tooltip = (id: string) =>
    isManuallyPlaced(id) ? manuallyTitle : strategyTitle;

  const sideColor = (side: TPlaceOrderSide) =>
    (side === 'Buy'
      ? theme.palette.purchaseButtons.green
      : theme.palette.purchaseButtons.red) as string;

  const typeColor = (id: string, side: TPlaceOrderSide) =>
    isManuallyPlaced(id) ? theme.palette.tab.light : sideColor(side);

  return {
    tooltip,
    sideColor,
    typeColor,
    manuallyTitle,
    strategyTitle,
  };
};
