import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import { useI18next } from '@trader/services';
import { IInitialTradingFormValues } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';

import * as Styled from './styled';

interface IProfit {
  openPrice?: string;
}

export const Profit: React.FC<IProfit> = observer(({ openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { formState, getValues } = useFormContext<IInitialTradingFormValues>();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const shouldRecalculateProfit =
    openPrice ||
    formState.dirtyFields.takeProfit ||
    formState.dirtyFields.takeProfitPips;

  const calculateProfit = (shouldAvoidPipsValidation?: boolean) => {
    trading.calculateProfit(
      {
        amount: getValues().amount,
        takeProfit: getValues().takeProfit,
        currentPrice: getValues().price,
        openPrice: openPrice,
        side: getValues().side,
        orderType: getValues().orderType,
        takeProfitType: getValues().takeProfitType,
      },
      shouldAvoidPipsValidation
    );
  };

  useEffect(() => {
    if (!shouldRecalculateProfit) {
      calculateProfit();
    }
  }, [shouldRecalculateProfit, trading.currencyConvert, getValues().amount]);

  useEffect(() => {
    if (shouldRecalculateProfit) {
      calculateProfit();
    }
  }, [
    shouldRecalculateProfit,
    instrument.ask,
    instrument.bid,
    getValues().amount,
  ]);

  useEffect(() => {
    calculateProfit(true);
  }, [getValues().side, getValues().orderType]);

  if (formState.errors.takeProfit || formState.errors.takeProfitPips) {
    return (
      <Styled.InputLabel>
        {translate('COMMON.LABELS.PROFIT')}: --
      </Styled.InputLabel>
    );
  }

  return (
    <Styled.InputLabel>
      {translate('COMMON.LABELS.PROFIT')}: {trading.profit}
    </Styled.InputLabel>
  );
});
