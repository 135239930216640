import React from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { TInstrumentEntity, useMst } from '@trader/store';
import { formatByPipSize } from '@trader/utils';

// TODO (ok): will add localization and proper styles after we get the last design
export const PerformanceSummary: React.FC = observer(() => {
  const store = useMst();
  const backTesting = store.pages.muliBands.backTesting;

  const symbol = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.symbolOption?.value || ''
  );

  const takeProfit = backTesting?.summary?.totalProfit || 0;
  const avgProfit = backTesting?.summary?.avgProfit || 0;
  const maxProfit = backTesting?.summary?.maxProfit || 0;

  return (
    <Styled.Content>
      <Styled.Item>
        <span>Take Profit:</span>
        <Styled.ItemValue>
          {formatByPipSize(takeProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>Average Profit:</span>
        <Styled.ItemValue>
          {formatByPipSize(avgProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>Max Profit:</span>
        <Styled.ItemValue>
          {formatByPipSize(maxProfit, symbol?.pipSize)}
        </Styled.ItemValue>
      </Styled.Item>
      <Styled.Item>
        <span>Number of Trades:</span>
        <Styled.ItemValue>
          {backTesting?.summary?.numberOfTrades}
        </Styled.ItemValue>
      </Styled.Item>
    </Styled.Content>
  );
});
