import { getRoot, IAnyStateTreeNode, Instance, types } from 'mobx-state-tree';

import { convertToOption, getDateInterval, runInAction } from '@trader/utils';
import {
  IBackTestingPosition,
  IBackTestingSummary,
  IOption,
  TOptions,
} from '@trader/types';

import { pingBackTestAsync, startBackTestingAsync } from './actions';
import {
  highMultiplier,
  lowMultiplier,
  multiplierStep,
} from '@trader/constants';

export const backTestingModel = types
  .model('backTestingModel', {
    id: types.maybeNull(types.number),
    isOpen: false,
    isPending: false,
    quantity: types.number,
    lowestMultiplier: types.number,
    highestMultiplier: types.number,
    multiplierStep: types.number,
    from: types.Date,
    to: types.Date,
    symbolOption: types.frozen<IOption | null>(null),
    summary: types.frozen<IBackTestingSummary | null>(null),
    positions: types.frozen<Array<IBackTestingPosition>>([]),

    startBackTestingAsync,
    pingBackTestAsync,
  })
  .views(store => ({
    getSymbolOption: (): TOptions => {
      const root = getRoot(store) as IAnyStateTreeNode;

      if (store.symbolOption) {
        return [store.symbolOption];
      }

      const tradingSymbol = root.pages.trading.getInstrumentSymbolByLayout();
      const muliBandsSymbol = root.pages.muliBands.symbol;

      return muliBandsSymbol
        ? convertToOption(muliBandsSymbol)
        : convertToOption(tradingSymbol);
    },
  }))
  .actions(store => ({
    runInAction,
    toggleBackTest: () => {
      store.isOpen = !store.isOpen;
    },
    clear: () => {
      store.id = null;
      store.isOpen = false;
      store.isPending = false;
      store.symbolOption = null;
      store.quantity = 0;
      store.lowestMultiplier = lowMultiplier;
      store.highestMultiplier = highMultiplier;
      store.multiplierStep = multiplierStep;
      store.from = getDateInterval('prevDay').from;
      store.to = getDateInterval('prevDay').to;
      store.summary = null;
      store.positions = [];
    },
    setSummary: (summary: IBackTestingSummary) => {
      store.summary = summary;
      store.isPending = false;
    },
    setPosition: (position: IBackTestingPosition) => {
      store.positions = [...store.positions, position];
    },
  }));

export const backTesting = types.optional(backTestingModel, {
  id: null,
  symbolOption: null,
  quantity: 0,
  lowestMultiplier: lowMultiplier,
  highestMultiplier: highMultiplier,
  multiplierStep: multiplierStep,
  ...getDateInterval('prevDay'),
});

export type TBackTestingStore = Instance<typeof backTestingModel>;
