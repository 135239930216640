import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';

import {
  getLossPipsFromRate,
  getLossRateFromPips,
  getMaxDigitWithDot,
  getProfitPipsFromRate,
  getProfitRateFromPips,
  getStepBasedOnTickSize,
  handleIndicesPipSize,
} from '@trader/utils';
import {
  IChangeFieldEvent,
  IInitialTradingFormValues,
  TAddProtectionType,
  TOptions,
} from '@trader/types';
import { useI18next } from '@trader/services';
import { Controller, Select } from '@trader/components';
import { TInstrumentEntity, useMst } from '@trader/store';

import { useGetMinMaxValues } from './useGetMinMaxValues';
import { Profit } from './components/profit';
import { Loss } from './components/loss';

import * as Styled from './styled';
import { useTriggerTradingFormFields } from '@trader/hooks';

interface IProtection {
  openPrice?: string;
}

export const Protection: React.FC<IProtection> = observer(({ openPrice }) => {
  const store = useMst();
  const { translate } = useI18next();
  const { control, register, setValue, trigger, getValues } =
    useFormContext<IInitialTradingFormValues>();
  const { triggerProfitFields, triggerLossFields } =
    useTriggerTradingFormFields();

  const { maxTakeProfit, minTakeProfit, minStopLoss, maxStopLoss } =
    useGetMinMaxValues();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const stopLossType = getValues().stopLossType;
  const takeProfitType = getValues().takeProfitType;
  const isStopLoss = getValues().isStopLoss;
  const isTakeProfit = getValues().isTakeProfit;
  const stopLossValue = getValues().stopLoss;
  const profitValue = getValues().takeProfit;

  const pipSize = handleIndicesPipSize(
    instrument.pipSize,
    instrument?.category || undefined,
    instrument?.minOrderSizeIncrement
  );

  const step = getStepBasedOnTickSize(pipSize, instrument.tickSize);

  const options: TOptions = [
    {
      title: translate('COMMON.LABELS.RATE'),
      value: 'Rate',
      id: 'Rate',
    },
    {
      title: translate('COMMON.LABELS.PIPS'),
      value: 'Pips',
      id: 'Pips',
    },
  ];

  const handleChangeType = (
    event: IChangeFieldEvent<TOptions>,
    type: 'stopLossType' | 'takeProfitType'
  ) => {
    const value = event.target.value[0].value as TAddProtectionType;
    const triggerNameRate =
      type === 'takeProfitType' ? 'takeProfit' : 'stopLoss';
    const triggerNamePips =
      type === 'takeProfitType' ? 'takeProfitPips' : 'stopLossPips';

    setValue(type, value);
    trigger([triggerNameRate, triggerNamePips]);
  };

  const handleToggle = (
    type: 'isTakeProfit' | 'isStopLoss',
    value: boolean
  ) => {
    const triggerNameRate = type === 'isTakeProfit' ? 'takeProfit' : 'stopLoss';
    const triggerNamePips =
      type === 'isTakeProfit' ? 'takeProfitPips' : 'stopLossPips';

    setValue(type, value);
    trigger([triggerNameRate, triggerNamePips]);
  };

  const handleChangeTakeProfit = useCallback(
    (event: IChangeFieldEvent) => {
      const value = event.target.value;
      setValue(
        'takeProfitPips',
        getProfitPipsFromRate(
          value,
          instrument.tickSize,
          trading.side,
          instrument.ask,
          instrument.bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize,
          getValues().orderType !== 'Market' ? getValues().price : undefined
        ).toString()
      );
      triggerProfitFields();

      trading.calculateProfit({
        amount: getValues().amount,
        takeProfit: getValues().takeProfit,
        takeProfitType: getValues().takeProfitType,
        currentPrice: getValues().price,
        openPrice: openPrice,
        side: getValues().side,
        orderType: getValues().orderType,
      });
    },
    [trading.side, instrument.bid, instrument.ask]
  );

  const handleChangeTakeProfitPips = useCallback(
    (event: IChangeFieldEvent) => {
      const value = event.target.value;

      setValue(
        'takeProfit',
        getProfitRateFromPips(
          value,
          instrument.tickSize,
          trading.side,
          instrument.ask,
          instrument.bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize,
          getValues().orderType !== 'Market' ? getValues().price : undefined
        )
      );

      triggerProfitFields();

      trading.calculateProfit(
        {
          amount: getValues().amount,
          takeProfit: getValues().takeProfit,
          currentPrice: getValues().price,
          openPrice: openPrice,
          side: getValues().side,
          orderType: getValues().orderType,
          takeProfitType: getValues().takeProfitType,
        },
        true
      );
    },
    [trading.side, instrument.bid, instrument.ask]
  );

  const handleChangeStopLoss = useCallback(
    (event: IChangeFieldEvent) => {
      const value = event.target.value;
      setValue(
        'stopLossPips',
        getLossPipsFromRate(
          value,
          instrument.tickSize,
          trading.side,
          instrument.ask,
          instrument.bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize,
          getValues().orderType !== 'Market' ? getValues().price : undefined
        ).toString()
      );
      triggerLossFields();

      trading.calculateLoss({
        amount: getValues().amount,
        stopLoss: getValues().stopLoss,
        currentPrice: getValues().price,
        openPrice: openPrice,
        side: getValues().side,
        orderType: getValues().orderType,
        stopLossType: getValues().stopLossType,
      });
    },
    [trading.side, instrument.bid, instrument.ask]
  );

  const handleChangeStopLossPips = useCallback(
    (event: IChangeFieldEvent) => {
      const value = event.target.value;
      setValue(
        'stopLoss',
        getLossRateFromPips(
          value,
          instrument.tickSize,
          trading.side,
          instrument.ask,
          instrument.bid,
          pipSize,
          instrument.category || undefined,
          instrument.pipSize,
          getValues().orderType !== 'Market' ? getValues().price : undefined
        )
      );

      triggerLossFields();

      trading.calculateLoss(
        {
          amount: getValues().amount,
          stopLoss: getValues().stopLoss,
          currentPrice: getValues().price,
          openPrice: openPrice,
          side: getValues().side,
          orderType: getValues().orderType,
          stopLossType: getValues().stopLossType,
        },
        true
      );
    },
    [trading.side, instrument.bid, instrument.ask]
  );

  return (
    <Styled.Root>
      <Styled.Content>
        <Styled.Protection>
          <Styled.TakeProfit>
            <Styled.Toggle
              shouldHideControlInfo
              label={translate('COMMON.LABELS.TAKE_PROFIT')}
              checked={isTakeProfit}
              onChange={event =>
                handleToggle('isTakeProfit', event.target.checked)
              }
            />
            {isTakeProfit && (
              <Styled.InputWrapper>
                <Styled.Select>
                  <Select
                    options={options}
                    value={options.filter(o => o.value === takeProfitType)}
                    onChange={event =>
                      handleChangeType(event, 'takeProfitType')
                    }
                  />
                </Styled.Select>
                <Styled.Label>
                  <Profit openPrice={openPrice} />
                  {takeProfitType !== 'Rate' && (
                    <Styled.InputLabel>
                      {translate('COMMON.LABELS.RATE')}: {profitValue}
                    </Styled.InputLabel>
                  )}
                </Styled.Label>
                {takeProfitType === 'Rate' && (
                  <Controller
                    type='orderInput'
                    isFocus
                    shouldCheckActivityOfButtons={false}
                    minValue={minTakeProfit}
                    maxValue={maxTakeProfit}
                    fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
                    step={step}
                    control={control}
                    onCustomChange={handleChangeTakeProfit}
                    {...register('takeProfit')}
                  />
                )}
                {takeProfitType === 'Pips' && (
                  <Controller
                    type='orderInput'
                    shouldCheckActivityOfButtons={false}
                    minValue={minTakeProfit}
                    maxValue={maxTakeProfit}
                    fixDigitAfterDot={0}
                    step={1}
                    onCustomChange={handleChangeTakeProfitPips}
                    control={control}
                    {...register('takeProfitPips')}
                  />
                )}
              </Styled.InputWrapper>
            )}
          </Styled.TakeProfit>
          <Styled.StopLoss>
            <Styled.Toggle
              shouldHideControlInfo
              label={translate('COMMON.LABELS.STOP_LOSS')}
              checked={isStopLoss}
              onChange={event =>
                handleToggle('isStopLoss', event.target.checked)
              }
            />
            {isStopLoss && (
              <Styled.InputWrapper>
                <Styled.Select>
                  <Select
                    options={options}
                    value={options.filter(o => o.value === stopLossType)}
                    onChange={event => handleChangeType(event, 'stopLossType')}
                  />
                </Styled.Select>
                <Styled.Label>
                  <Loss openPrice={openPrice} />
                  {stopLossType !== 'Rate' && (
                    <Styled.InputLabel>
                      {translate('COMMON.LABELS.RATE')}: {stopLossValue}
                    </Styled.InputLabel>
                  )}
                </Styled.Label>
                {stopLossType === 'Rate' && (
                  <Controller
                    type='orderInput'
                    isFocus
                    shouldCheckActivityOfButtons={false}
                    minValue={minStopLoss}
                    maxValue={maxStopLoss}
                    fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
                    step={step}
                    control={control}
                    onCustomChange={handleChangeStopLoss}
                    {...register('stopLoss', {})}
                  />
                )}
                {stopLossType === 'Pips' && (
                  <Controller
                    type='orderInput'
                    shouldCheckActivityOfButtons={false}
                    minValue={minStopLoss}
                    maxValue={maxStopLoss}
                    fixDigitAfterDot={0}
                    step={1}
                    onCustomChange={handleChangeStopLossPips}
                    control={control}
                    {...register('stopLossPips')}
                  />
                )}
              </Styled.InputWrapper>
            )}
          </Styled.StopLoss>
        </Styled.Protection>
      </Styled.Content>
    </Styled.Root>
  );
});
