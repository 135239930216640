import { useCallback } from 'react';

import { TChallengeEntity, TTradingAccountEntity, useMst } from '@trader/store';
import { IChallenge as IChallengeBE } from '@trader/api';
import { useI18next } from '@trader/services';
import { EChallenge, EChallengeResult, EChallengeStatus } from '@trader/types';
import { useNavigation } from '@trader/hooks';
import { NAVIGATE_TO } from '@trader/constants';

import * as Styled from '../styled';

export const useRenderCellActions = (
  state: TChallengeEntity,
  account: TTradingAccountEntity,
  shouldDisplayAccTypeIcon?: boolean
) => {
  const { translate } = useI18next();
  const store = useMst();
  const { navigateTo } = useNavigation();

  const renderStatus = useCallback(() => {
    const isPositive = state.status === EChallengeStatus.Active;
    const isNegative = state.status === EChallengeStatus.Disable;

    return (
      <Styled.Value $isPositive={isPositive} $isNegative={isNegative}>
        {isNegative
          ? translate('COMMON.LABELS.INACTIVE')
          : translate(`COMMON.LABELS.${state.status}`)}
      </Styled.Value>
    );
  }, []);

  const renderProfitLoss = useCallback((value: number) => {
    if (!value) {
      return '-';
    }

    const isPositive = value > 0;
    const isNegative = value < 0;

    return (
      <Styled.Value
        $isFullBgItem
        $isPositive={isPositive}
        $isNegative={isNegative}
      >
        {isPositive ? '+' : '-'}
        {account.currencySymbol}
        {value.toFixed(2).replace('-', '')}
      </Styled.Value>
    );
  }, []);

  const renderResult = useCallback(() => {
    const isPositive = state.result === EChallengeResult.Completed;
    const isProgress = state.result === EChallengeResult.InProgress;
    const isNegative =
      state.result === EChallengeResult.Failed ||
      state.result === EChallengeResult.Cancelled;

    const translationMap: Record<IChallengeBE['result'], string> = {
      Completed: translate('COMMON.LABELS.COMPLETED'),
      Failed: translate('COMMON.LABELS.FAILED'),
      Cancelled: translate('COMMON.LABELS.CANCELLED'),
      'In Progress': translate('COMMON.LABELS.IN_PROGRESS'),
    };

    return (
      <Styled.Value
        $isPositive={isPositive}
        $isNegative={isNegative}
        $isProgress={isProgress}
      >
        {translationMap[state.result]}
      </Styled.Value>
    );
  }, []);

  const renderChangeAcc = useCallback(() => {
    const change = async () => {
      if (account?.isInUse) {
        navigateTo(NAVIGATE_TO.main.dashboard);
        return;
      }

      await store.entities.tradingAccounts.changeTradingAccountAsync.run({
        tradingId: account?.tradingId || 0,
      });
      navigateTo(NAVIGATE_TO.main.dashboard);
    };

    if (state?.status === EChallengeStatus.Disable) {
      return null;
    }

    return (
      <Styled.ChangeAccount
        onClick={change}
        iconType='launchTradeAccount'
        isSingleIcon
      />
    );
  }, [account]);

  const renderAccountIcon = useCallback(() => {
    if (shouldDisplayAccTypeIcon) {
      return (
        <Styled.Value>
          {state.hierarchyType === EChallenge.Slave && (
            <Styled.AccountType iconType='accountTypeSlave' />
          )}
          {state.hierarchyType === EChallenge.Master && (
            <Styled.AccountType iconType='accountTypeMaster' />
          )}
        </Styled.Value>
      );
    }
    return null;
  }, [state.hierarchyType]);

  return {
    renderStatus,
    renderProfitLoss,
    renderResult,
    renderChangeAcc,
    renderAccountIcon,
  };
};
