import styled, { css } from 'styled-components';
import { Wrapper } from '../wrapper';
import { Typography } from '../typography';
import { conditionApply } from '@trader/utils';

interface IRoot {
  $isShowDetails: boolean;
}

export const Root = styled(Wrapper)<IRoot>`
  ${({ $isShowDetails, theme }) => css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 550px;
    border-radius: 8px;
    margin-left: 16px;
    margin-right: -16px;
    display: none;
    transition: all 0.4s ease-in;

    ${conditionApply(
      [$isShowDetails],
      css`
        display: flex;
      `
    )}

    ${theme.breakpoints.down('sm')} {
      overflow: auto;
      width: 100%;
      margin-left: 0;
    }
  `};
`;

export const BalanceContainer = styled(Wrapper)`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    width: 100%;

    ${theme.breakpoints.down('xl')} {
      font-size: 10px;
    }

    ${theme.breakpoints.down('sm')} {
      color: ${theme.palette.common.white};
    }
  `};
`;

export const Value = styled(Typography)`
  ${({ theme }) => css`
    font-size: 13px;
    font-weight: 500;
    text-align: left;

    ${theme.breakpoints.down('xl')} {
      font-size: 12px;
    }

    ${theme.breakpoints.down('sm')} {
      color: ${theme.palette.common.white};
    }
  `};
`;
