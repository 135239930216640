import { useCallback, useEffect } from 'react';

import { TPositionMetricEntity, useMst } from '@trader/store';
import {
  EOrderSide,
  EStripName,
  IBackTestingPosition,
  IBackTestingSummary,
} from '@trader/types';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

type TMessageType =
  | 'OrderCreated'
  | 'PositionCreated'
  | 'StrategyInitialized'
  | 'StrategyFinished'
  | 'PositionDeleted'
  | 'TradeResult';

interface IMessage {
  name: TMessageType;
  id: number;
  side: EOrderSide;
  bandId: string;
  retCode: number;
}

export const useMuliBands = () => {
  const store = useMst();
  const muliBands = store.pages.muliBands;
  const idToken = store.auth.tokens.idToken || '';

  const handleUpdate = useCallback(
    (message: IMessage | IBackTestingPosition | IBackTestingSummary) => {
      if (message.name === 'OrderCreated') {
        store.pages.muliBands.updateStripOrder(
          EStripName[message.bandId],
          message.id,
          EOrderSide[message.side]
        );
      }

      if (message.name === 'PositionCreated') {
        store.entities.positionsMetrics.getPositionsMetricsAsync.run();
        const position =
          store.entities.positionsMetrics.get<TPositionMetricEntity>(
            message.id
          );
        store.pages.muliBands.updateStripPosition(
          EStripName[message.bandId],
          message.id,
          position?.openPrice,
          position?.pl
        );
        store.pages.muliBands.runInAction(() => {
          store.pages.muliBands.firstOpenedPositionTime = new Date().toString();
        });
      }

      if (message.name === 'StrategyInitialized') {
        store.pages.muliBands.getExistingDataAsync.run(
          store.pages.muliBands.symbol ||
            store.pages.trading.getInstrumentSymbolByLayout()
        );
      }

      if (message.name === 'PositionDeleted') {
        store.pages.muliBands.backTesting.setPosition(
          message as IBackTestingPosition
        );
      }

      if (message.name === 'StrategyFinished') {
        store.pages.muliBands.backTesting.setSummary(
          message as IBackTestingSummary
        );
      }
    },
    []
  );

  const { unsubscribe, subscribe } = useHub<IMessage>({
    url: '/strategy',
    hub: EConnectionHub.MuliBands,
    subscriber: EConnectionHubSubscriber.MuliBands,
    onMessage: handleUpdate,
    invokedName: 'event',
  });

  useEffect(() => {
    subscribe();
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [muliBands.id, idToken, store.auth.isAuth]);
};
