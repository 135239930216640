import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { IInstrumentTradersTrendBE } from '@trader/api';
import { Tooltip, Wrapper } from '@trader/components';
import { useI18next } from '@trader/services';
import {
  addSeparatorToIntegerNumber,
  formatByPipSize,
  getInstrumentDifference,
} from '@trader/utils';

import * as Styled from './styled';

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const KeyStatistics: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const [tradersTrend, setTradersTrend] = useState<IInstrumentTradersTrendBE>({
    sold: 0,
    bought: 0,
  });

  const symbol = store.pages.trading.getInstrumentSymbolByLayout();
  const instrument = store.entities.instruments.get<
    TInstrumentEntity | undefined
  >(symbol);

  useEffect(() => {
    async function getTradersTrend() {
      if (!instrument) return;
      const trends = await instrument.getInstrumentTradersTrendAsync.run(
        symbol
      );
      trends && setTradersTrend(trends);
    }

    getTradersTrend();
  }, [symbol]);

  if (!instrument) {
    return <Styled.Root />;
  }

  const swapTypeTitle =
    instrument.swapType !== null
      ? translate(`SWAP_TYPES.${instrument.swapType}`)
      : '--';

  const renderTradingHours = () => {
    const day = new Date().getUTCDay();

    if (!instrument.sessions || !instrument.sessions[day]) {
      return '--';
    }

    const tradingHours = instrument.sessions[day].tradingHours;

    return tradingHours.map(time => (
      <Styled.ItemValue key={time.from}>
        {time.from || '--'} - {time.to || '--'}
      </Styled.ItemValue>
    ));
  };

  const renderRolloverDate = (rolloverDate: string | null) => {
    if (!rolloverDate) {
      return '--';
    }

    const [day, monthAbbr] = rolloverDate.split('-');
    const date = new Date(`2000-${monthAbbr}-${day}`);
    const fullMonth = date.toLocaleString('en', { month: 'long' });
    return `${day} ${fullMonth}`;
  };

  return (
    <Styled.Root>
      <Styled.Main>
        <Wrapper className='firstColumn'>
          <Styled.Stats>
            <Styled.Title>
              {translate('COMMON.LABELS.DAILY_STATS')}
            </Styled.Title>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.OPEN')}
              </Styled.ItemTitle>
              <Open symbol={symbol} />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle className='daily-stats-high'>
                {translate('COMMON.LABELS.HIGH')}
              </Styled.ItemTitle>
              <High symbol={symbol} />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle className='daily-stats-low'>
                {translate('COMMON.LABELS.LOW')}
              </Styled.ItemTitle>
              <Low symbol={symbol} />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.CLOSE')}
              </Styled.ItemTitle>
              <Close symbol={symbol} />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.CHANGE')}
              </Styled.ItemTitle>
              <ChangeValue symbol={symbol} type='number' />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.CHANGE')} %
              </Styled.ItemTitle>
              <ChangeValue symbol={symbol} type='percent' />
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.DAILY_CHANGE')}
              </Styled.ItemTitle>
              <DailyChange symbol={symbol} />
            </Styled.Item>
          </Styled.Stats>
          <Styled.Conditions>
            <Styled.Title>
              {translate('COMMON.LABELS.TRADING_CONDITIONS')}
            </Styled.Title>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.TRADING_HOURS')}
              </Styled.ItemTitle>
              <Wrapper className='tradingHours'>{renderTradingHours()}</Wrapper>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.TRADING_STATUS')}
              </Styled.ItemTitle>
              <Styled.ItemValue>
                {instrument.isOpenTrading
                  ? translate('COMMON.LABELS.OPEN_FOR_TRADING')
                  : translate('COMMON.LABELS.CLOSED_FOR_TRADING')}
              </Styled.ItemValue>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.ROLLOVER_DATE')}
              </Styled.ItemTitle>
              <Styled.ItemValue>
                {renderRolloverDate(instrument.rolloverDate)}
              </Styled.ItemValue>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.CONTRACT_SIZE')}
              </Styled.ItemTitle>
              <Styled.ItemValue>
                {addSeparatorToIntegerNumber(instrument.contractSize)}
              </Styled.ItemValue>
            </Styled.Item>
          </Styled.Conditions>
        </Wrapper>
        <Styled.Trend>
          <Styled.Title>
            {translate('COMMON.LABELS.TRADERS_TREND')}
          </Styled.Title>
          <Styled.TrendContainer>
            <Styled.TrendMain
              $isFetched={
                !instrument.getInstrumentSpecificationAsync.inProgress ||
                !instrument.getInstrumentTradersTrendAsync.inProgress
              }
              $type='sold'
              $isGray={!tradersTrend.sold && !tradersTrend.bought}
              $width={tradersTrend.sold}
            >
              <Styled.TrendPrice className='sold'>
                {tradersTrend.sold}%
              </Styled.TrendPrice>
              <Styled.TrendTitle>
                {translate('COMMON.LABELS.SOLD')}
              </Styled.TrendTitle>
            </Styled.TrendMain>
            <Styled.TrendMain
              $isFetched={
                !instrument.getInstrumentSpecificationAsync.inProgress ||
                !instrument.getInstrumentTradersTrendAsync.inProgress
              }
              $type='bought'
              $isGray={!tradersTrend.sold && !tradersTrend.bought}
              $width={tradersTrend.bought}
            >
              <Styled.TrendPrice className='bought'>
                {tradersTrend.bought}%
              </Styled.TrendPrice>
              <Styled.TrendTitle>
                {translate('COMMON.LABELS.BOUGHT')}
              </Styled.TrendTitle>
            </Styled.TrendMain>
          </Styled.TrendContainer>
        </Styled.Trend>
        <Wrapper className='lastColumn'>
          <Styled.Stats>
            <Styled.Title>{translate('COMMON.LABELS.SWAPS')}</Styled.Title>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.SWAP_TYPE')}
              </Styled.ItemTitle>
              <Tooltip title={swapTypeTitle}>
                <Styled.ItemValue className='swapType'>
                  {swapTypeTitle}
                </Styled.ItemValue>
              </Tooltip>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.SWAP_LONG')}
              </Styled.ItemTitle>
              <Styled.ItemValue>{instrument?.swapRateLong}</Styled.ItemValue>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemTitle>
                {translate('COMMON.LABELS.SWAP_SHORT')}
              </Styled.ItemTitle>
              <Styled.ItemValue>{instrument?.swapRateShort}</Styled.ItemValue>
            </Styled.Item>
          </Styled.Stats>
          <Styled.Conditions>
            <Styled.Title>
              {translate('COMMON.LABELS.SWAPS_RATES')}
            </Styled.Title>
            {days.map((day, index) => (
              <Styled.Item key={day}>
                <Styled.ItemTitle>
                  {translate(`DAYS.${day.toUpperCase()}`)}
                </Styled.ItemTitle>
                <Styled.ItemValue>
                  {instrument?.swapRates[index]}
                </Styled.ItemValue>
              </Styled.Item>
            ))}
          </Styled.Conditions>
        </Wrapper>
      </Styled.Main>
    </Styled.Root>
  );
});

interface IChildren {
  symbol: string;
  type?: 'percent' | 'number';
}

const Open: React.FC<IChildren> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.ItemValue>
      {formatByPipSize(instrument.open, instrument.pipSize)}
    </Styled.ItemValue>
  );
});

const High: React.FC<IChildren> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const currentPrice = Number(instrument?.ask || 0);
  const high =
    currentPrice > instrument?.high || 0 ? currentPrice : instrument?.high || 0;

  return (
    <Styled.ItemValue>
      {formatByPipSize(high, instrument.pipSize)}
    </Styled.ItemValue>
  );
});

const Low: React.FC<IChildren> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const currentPrice = Number(instrument?.ask || 0);
  const low =
    currentPrice < instrument?.low || 0 ? currentPrice : instrument?.low || 0;

  return (
    <Styled.ItemValue>
      {formatByPipSize(low, instrument.pipSize)}
    </Styled.ItemValue>
  );
});

const Close: React.FC<IChildren> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.ItemValue>
      {formatByPipSize(instrument.close, instrument.pipSize)}
    </Styled.ItemValue>
  );
});

const ChangeValue: React.FC<IChildren> = observer(({ symbol, type }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  const { openCloseDifferenceInPercent, openCloseDifference } =
    getInstrumentDifference(
      instrument?.ask,
      instrument?.close,
      instrument?.pipSize
    );

  if (type === 'percent') {
    return <Styled.ItemValue>{openCloseDifferenceInPercent}%</Styled.ItemValue>;
  }

  return <Styled.ItemValue>{openCloseDifference}</Styled.ItemValue>;
});

const DailyChange: React.FC<IChildren> = observer(({ symbol }) => {
  const store = useMst();
  const instrument = store.entities.instruments.get<TInstrumentEntity>(symbol);

  return (
    <Styled.ItemValue>
      {formatByPipSize(instrument?.ask, instrument.pipSize)} -{' '}
      {formatByPipSize(instrument.close, instrument.pipSize)}
    </Styled.ItemValue>
  );
});
