import React, { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { LOCAL_STORAGE_KEYS, pingStrategyInterval } from '@trader/constants';
import { EChartLayouts, EGeneralTabContainerType } from '@trader/types';
import { useLayouts, useMuliBands, useNavigation } from '@trader/hooks';
import { TInstrumentEntity, useMst } from '@trader/store';
import { localStorageService } from '@trader/services';
import {
  AdvanceChart,
  BottomBar,
  InstrumentDetailsHeader,
  Modals,
  TopBar,
} from '@trader/containers';

import { BackTestingContent } from './components/backTestingContent';
import { InfoBlocks } from './components/infoBlocks';
import { RightMenu } from './components/rightMenu';
import { Assets } from './components/assets';

import * as Styled from './styled';

export const MuliBands: React.FC = observer(() => {
  const store = useMst();
  const { goBackNavigate } = useNavigation();

  useMuliBands();

  const idToken = store.auth.tokens.idToken;

  useEffect(() => {
    if (!store.user.settings.isMuliBandsDisplayedByUser) {
      goBackNavigate();
    }
  }, [store.user.settings.isMuliBandsDisplayedByUser]);

  const tradingSymbol = store.pages.trading.getInstrumentSymbolByLayout();
  const muliBandsSymbol = store.pages.muliBands.symbol;

  const instrument = store.entities.instruments.get<TInstrumentEntity>(
    tradingSymbol || muliBandsSymbol
  );

  const defaultPeriod = store.pages.muliBands.chartPeriod;
  const generalTab = store.pages.muliBands.generalTab;

  const shouldDisplayBackTestingContent =
    store.pages.muliBands.backTesting.id &&
    store.pages.muliBands.backTesting.isOpen;

  useLayouts(instrument.symbol);

  const handleChangeSymbol = useCallback((value: string) => {
    store.pages.muliBands.runInAction(() => {
      store.pages.muliBands.symbol = value;
    });
  }, []);

  const renderFullChartContainer = useMemo(
    () => ({
      ordersAndPositions: <Assets navigationKey='' />,
      alerts: <Assets selectedTab='price-alerts' navigationKey='' />,
    }),
    [generalTab.id]
  );

  useEffect(() => {
    store.entities.instruments.getInstrumentSpecificationAsync.run(
      instrument.symbol
    );
    store.pages.muliBands.getInitialDataAsync.run(instrument.symbol);

    const interval = setInterval(() => {
      store.pages.muliBands.pingMuliBandAsync.run();
    }, pingStrategyInterval);

    return () => {
      store.pages.muliBands.deleteMuliBandAsync.run();
      clearInterval(interval);
    };
  }, [instrument.symbol, idToken]);

  useEffect(() => {
    store.entities.ordersMetrics.getOrdersMetricsAsync.run({
      shouldClearBeforeMerge: true,
    });
    store.user.getProfileDemoAsync.run();
    store.entities.challenges.getChallengesAsync.run();
    return () => {
      localStorageService.remove(LOCAL_STORAGE_KEYS.tradingViewSettings);
    };
  }, []);

  if (!instrument) {
    return (
      <Styled.Root>
        <TopBar />
        <Styled.Main />
        <BottomBar />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <TopBar withChartLayoutsSelection={false} />
      <Styled.Body>
        <Styled.Content>
          <Styled.Main>
            <InstrumentDetailsHeader
              onChangeSymbol={handleChangeSymbol}
              isMuliBands
              searchPlaceholder={'COMMON.LABELS.SEARCH_INSTRUMENT'}
              instrument={{
                ...instrument,
                layoutNumber: EChartLayouts.FirstLayout,
              }}
            />

            {generalTab.id &&
              generalTab.type === EGeneralTabContainerType.FullChart &&
              renderFullChartContainer[generalTab.id]}

            {!store.pages.muliBands.getInitialDataAsync.inProgress &&
              generalTab.type !== EGeneralTabContainerType.FullChart && (
                <React.Fragment>
                  <InfoBlocks />
                  <AdvanceChart
                    isMuliBands
                    hasVolume
                    timeframe='1D'
                    extraDisabledFeatures={[
                      'timeframes_toolbar',
                      'header_resolutions',
                      'main_series_scale_menu',
                      'property_pages',
                    ]}
                    defaultPeriod={defaultPeriod}
                    instrumentSymbol={instrument.symbol}
                    layoutNumber={EChartLayouts.FirstLayout}
                  />
                </React.Fragment>
              )}
          </Styled.Main>
          {shouldDisplayBackTestingContent && <BackTestingContent />}
        </Styled.Content>
        <RightMenu />
      </Styled.Body>
      <BottomBar />
      <Modals />
    </Styled.Root>
  );
});
