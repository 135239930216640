import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

import { IChangeFieldEvent, IOption, TOptions } from '@trader/types';
import { TInstrumentEntity, useMst } from '@trader/store';
import { pingStrategyInterval } from '@trader/constants';
import { Controller, Form } from '@trader/components';
import { convertFromOption } from '@trader/utils';
import { useDebounce } from '@trader/hooks';

import { useBackTestingFormValidation } from './formValidation';

import * as Styled from './styled';

interface IBackTestingInitialValues {
  symbol: IOption[];
  quantity: number;
  lowestMultiplier: number;
  highestMultiplier: number;
  multiplierStep: number;
  from: Date;
  to: Date;
}

export const BackTesting: React.FC = observer(() => {
  const store = useMst();

  const [searchedValue, setSearchedValue] = useState('');
  const [options, setOptions] = useState<IOption[]>([]);

  const idToken = store.auth.tokens.idToken;

  const muliBands = store.pages.muliBands;
  const backTesting = muliBands.backTesting;

  const validationSchema = useBackTestingFormValidation();

  const defaultFormValues = {
    symbol: backTesting.getSymbolOption(),
    lowestMultiplier: backTesting.lowestMultiplier,
    highestMultiplier: backTesting.highestMultiplier,
    multiplierStep: backTesting.multiplierStep,
    from: backTesting.from,
    to: backTesting.to,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      backTesting.pingBackTestAsync.run();
    }, pingStrategyInterval);

    return () => {
      clearInterval(interval);
    };
  }, [backTesting.id, backTesting.isOpen, idToken]);

  useEffect(() => {
    return () => {
      backTesting.clear();
    };
  }, []);

  useDebounce(async () => {
    if (searchedValue) {
      const instruments =
        await store.entities.instruments.getInstrumentsSearchAsync.run(
          searchedValue
        );
      setOptions(
        instruments?.length
          ? instruments?.map(i => ({ title: i, value: i }))
          : []
      );
    }
  }, [searchedValue]);

  const handleSubmit = (data: IBackTestingInitialValues) => {
    const { symbol, ...backTestConfig } = data;

    backTesting.startBackTestingAsync.run({
      symbolOption: symbol[0],
      backTestConfig,
    });
  };

  const customSymbolChange = async (event: IChangeFieldEvent<TOptions>) => {
    if (event.target.value[0]) {
      await store.entities.instruments.getInstrumentSpecificationAsync.run(
        event.target.value[0].value
      );
      backTesting.runInAction(() => {
        backTesting.symbolOption = event.target.value[0];
      });
    } else {
      backTesting.runInAction(() => {
        backTesting.symbolOption = null;
      });
    }
  };

  return (
    <Styled.Root>
      <Styled.Title>Backtest config</Styled.Title>
      <Form<IBackTestingInitialValues>
        onSubmit={(_reset, data) => {
          handleSubmit(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        <Controller
          type='autocompleteField'
          placeholder='Symbol'
          name='symbol'
          isSearchable
          shouldHideInput
          withCheckbox={false}
          onHandeSearchableValue={setSearchedValue}
          onCustomChange={customSymbolChange}
          options={options}
        />
        <Quantity />
        <Controller
          type='textField'
          label='Lowest Multiplier'
          name='lowestMultiplier'
        />
        <Controller
          type='textField'
          label='Highest Multiplier'
          name='highestMultiplier'
        />
        <Controller
          type='textField'
          label='Multiplier Step'
          name='multiplierStep'
        />
        <Controller type='datePickerField' label='From Date' name='from' />
        <Controller type='datePickerField' label='To Date' name='to' />
        <Styled.ApplyChanges fullWidth type='submit'>
          Execute
        </Styled.ApplyChanges>
      </Form>
    </Styled.Root>
  );
});

const Quantity = observer(() => {
  const store = useMst();
  const backTesting = store.pages.muliBands.backTesting;
  const symbol = convertFromOption(backTesting.getSymbolOption());

  const { setValue, formState } = useFormContext<IBackTestingInitialValues>();

  useEffect(() => {
    if (formState.dirtyFields.symbol && !backTesting?.symbolOption?.value) {
      return setValue('quantity', 0);
    }

    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);

    if (instrument) {
      setValue('quantity', instrument.minOrderSize);
    } else {
      setValue('quantity', 0);
    }
  }, [symbol, formState.dirtyFields.symbol]);

  return (
    <Controller
      disabled={!symbol}
      type='textField'
      label='Quantity'
      name='quantity'
    />
  );
});
