import * as yup from 'yup';

import { TInstrumentEntity, useMst } from '@trader/store';
import { useI18next } from '@trader/services';
import { isValueTimesStep } from '@trader/utils';

export const useAmountSchema = (maxValue?: number) => {
  const { translate } = useI18next();
  const store = useMst();

  const instrument = store.trading.instrument as TInstrumentEntity;

  const amountSchema = yup
    .number()
    .min(
      instrument.minOrderSize,
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.minOrderSize,
      })
    )
    .max(
      maxValue || instrument.maxOrderSize,
      translate('COMMON.ERRORS.MUST_BE_LOWER_THAN', {
        amount: maxValue || instrument.maxOrderSize,
      })
    )
    .test(
      'increment',
      translate('COMMON.ERRORS.SHOULD_BE_MULTIPLE_OF_THE_MINIMUM_LOTS', {
        amount: instrument.minOrderSizeIncrement,
      }),
      value =>
        !!value && isValueTimesStep(value, instrument.minOrderSizeIncrement)
    )
    .required(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.minOrderSize,
      })
    )
    .typeError(
      translate('COMMON.ERRORS.MUST_BE_HIGHER_THAN', {
        amount: instrument.minOrderSize,
      })
    );

  return { amountSchema };
};
