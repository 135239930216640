import html2canvas from 'html2canvas';

class ImageGenerationService {
  async fromHtmlToDataUrl(options: {
    element: HTMLElement;
    imageType?: string;
  }) {
    const { element, imageType = 'png' } = options;

    const canvas = await html2canvas(element, { logging: false, scale: 2 });
    return canvas.toDataURL(`image/${imageType}`);
  }
}

export const imageGenerationService = new ImageGenerationService();
