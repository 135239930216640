import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, TOrderMetricEntity, useMst } from '@trader/store';
import { ModalTitle, Progress, TradingIsClosed } from '@trader/components';
import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
} from '@trader/hooks';
import { useI18next } from '@trader/services';

import {
  IOrderMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { ConfirmButton } from '../components/confirmButton';

import * as Styled from './styled';

const CancelOrder: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const [isOrderProcessing, setIsOrderProcessing] = useState(false);

  const modal = store.ui.modal;
  const orderId = modal.options.get('orderId');
  const metric = store.entities.ordersMetrics.get<TOrderMetricEntity>(orderId);

  const handleCancelOrder = () => {
    metric?.cancelOrderAsync.run(orderId)?.then(() => {
      setIsOrderProcessing(true);
    });

    // The values will be used in socket handlers
    // to show the notification about order correctly.
    modal.update({
      isOrderCanceled: true,
      orderId,
    });
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.CANCEL_ORDER')} />
      <TradingModalHeader<IOrderMetricStore> type='order' metric={metric} />
      <ConfirmButton
        isDisabled={metric?.cancelOrderAsync.inProgress || isOrderProcessing}
        isLoading={metric?.cancelOrderAsync.inProgress}
        onClick={handleCancelOrder}
        title={
          isOrderProcessing
            ? translate('COMMON.LABELS.PROCESSING')
            : translate('COMMON.LABELS.CANCEL_ORDER')
        }
      />
    </Styled.Root>
  );
});

export const CancelOrderWrapper: React.FC = observer(() => {
  const store = useMst();
  useCloseModalForAlreadyDeletedInstance('orderId');

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return <CancelOrder />;
});
