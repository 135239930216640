import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    margin: 10px 0;
    justify-content: center;
    min-width: 230px;
    padding: 0 10px;

    ${theme.breakpoints.down('sm')} {
      margin: 10px 0 -10px 0;
    }
  `}
`;
