import { IGetAccountStatementParams } from './models/accountStatement';

export const tradingRoutes = {
  placeOrder: () => '/trading/place-order',
  placeChallengeOrder: () => '/trading/place-order/challenge',
  cancelOrder: (orderId: string) => `/trading/cancel-order/${orderId}`,
  cancelChallengeOrder: (orderId: string) =>
    `/trading/cancel-order/${orderId}/challenge`,
  editOrder: (orderId: string) => `/trading/order/${orderId}`,
  editChallengeOrder: (orderId: string) =>
    `/trading/order/${orderId}/challenge`,
  closePosition: (positionId: string) =>
    `/trading/close-position/${positionId}`,
  closeChallengePosition: (positionId: string) =>
    `/trading/close-position/${positionId}/challenge`,
  editPosition: (positionId: string) => `/trading/position/${positionId}`,
  editChallengePosition: (positionId: string) =>
    `/trading/position/${positionId}/challenge`,
  getOrdersMetrics: '/trading/orders-metrics?pageSize=500',
  getAssetOrderMetrics: '/trading/asset-orders-metrics?pageSize=500',
  getPositionsMetrics: '/trading/positions-metrics?pageSize=500',
  getOrders: '/trading/orders',
  getPositions: '/trading/positions',
  getRequiredOpenCost: '/trading/required-open-cost',
  getPositionsHistory: '/trading/positions-history',
  getCostAndCharges: '/trading/cost-and-charges',
  getAccountStatement: ({
    from,
    to,
    platformLogin,
  }: IGetAccountStatementParams) =>
    `/trading/reports/account-statements?from=${from}&to=${to}&platformLogin=${platformLogin}`,
  closeAllPositions: '/trading/close-all-positions',
  cancelAllOrders: '/trading/cancel-all-orders',
  closeAllChallengePositions: '/trading/close-all-positions/challenge',
  cancelAllChallengeOrders: '/trading/cancel-all-orders/challenge',
} as const;
