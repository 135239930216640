import i18next from 'i18next';

import { TAlertEntity } from '@trader/store';
import { devLoggerService } from '@trader/services';
import { api, ICreateAlertBody } from '@trader/api';

import { getRootInstance } from '../../configureStore/configureStore';
import { createThunk } from '../../utils/asyncModel';
import { alertsSchema } from './schemas';

export const getAlertsAsync = createThunk<void, void>(
  () =>
    async function getAlerts(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const response = await api.Alert.getAlerts(_options);
      const alerts = response?.items?.map(item => {
        if (item.conditions[0].instrument) {
          return item;
        }
        return null;
      });

      try {
        root.entities.normalizeMerge(
          alerts?.filter(a => !!a),
          alertsSchema,
          true
        );
      } catch (e) {
        devLoggerService.error('Error in getAlertsAsync', e);
      }
    }
);

export const getAlertByIdAsync = createThunk<number, void>(
  (id: number) =>
    async function getAlert(this: unknown, _options, _flow) {
      const root = getRootInstance();

      const alert = await api.Alert.getAlert(id, _options);
      if (alert.conditions[0].instrument) {
        root.entities.alerts.add(id, alert);
      } else {
        root.notifications.add({
          message: i18next.t('WARNINGS.FETCHED_PRICE_ALERT_IS_EMPTY'),
          options: {
            variant: 'error',
          },
        });
      }
    }
);

export const createAlertAsync = createThunk<ICreateAlertBody, void>(
  body =>
    async function createAlert(this: unknown, _options, _flow) {
      const root = getRootInstance();

      await api.Alert.createAlert(body, _options);
      root.ui.modal.close();
      root.notifications.add({
        message: i18next.t('NOTIFICATIONS.ALERT_HAS_BEEN_CREATED'),
        options: {
          variant: 'success',
        },
      });
    }
);

export const editAlertAsync = createThunk<ICreateAlertBody, void>(
  body =>
    async function createAlert(this: unknown, _options, _flow) {
      const root = getRootInstance();

      await api.Alert.editAlert(body, _options);

      const editedAlert = root.entities.alerts.get<TAlertEntity>(body.id!);
      editedAlert.runInAction(() => {
        editedAlert.conditions[0].rightExpression.parameters = {
          Number: body.conditions[0].rightExpression.parameters.Number,
        };
        editedAlert.conditions[0].compareType = body.conditions[0].compareType;
      });

      root.ui.modal.close();
      root.notifications.add({
        message: i18next.t('NOTIFICATIONS.ALERT_HAS_BEEN_EDITED'),
        options: {
          variant: 'success',
        },
      });
    }
);

export const deleteAlertAsync = createThunk<number, void>(
  alertId =>
    async function createAlert(this: unknown, _options, _flow) {
      const root = getRootInstance();

      await api.Alert.deleteAlert(alertId, _options);
      root.ui.modal.close();
      root.entities.alerts.delete(alertId);
      root.notifications.add({
        message: i18next.t('NOTIFICATIONS.ALERT_HAS_BEEN_DELETED'),
        options: {
          variant: 'success',
        },
      });
    }
);
