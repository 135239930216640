import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { Wrapper } from '../wrapper';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .scroll-list-header {
      th {
        border-bottom: 1px solid ${theme.palette.background.default};
        background-color: ${theme.palette.background.paper};
      }
    }

    .icon.edit path {
      stroke: ${theme.palette.gray.dark} !important;
    }

    .icon.remove path {
      stroke: ${theme.palette.red.light} !important;
    }
  `}
`;

export const Actions = styled('div')`
  ${() => css`
    button {
      padding: 7px;
    }
  `};
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: 10px;
    font-weight: 500;
    color: ${theme.palette.primary.dark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
  `};
`;
