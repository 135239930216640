import { useMst } from '@trader/store';

export const useCloseModalForAlreadyDeletedInstance = (
  key: 'orderId' | 'positionId'
) => {
  const store = useMst();

  const entityType = key === 'orderId' ? 'ordersMetrics' : 'positionsMetrics';

  const id = store.ui.modal.options.get(key);

  const metric = store.entities[entityType].get(id);

  if (!metric) {
    store.ui.modal.close();
  }
};
