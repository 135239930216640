import { Fragment, ReactNode } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTheme } from 'styled-components';

import * as Styled from './styled';
import { IChangeFieldEvent } from '@trader/types';
import { ControlInfo } from '../controlInfo';

export interface IDatepicker {
  value?: Date | null;
  minValue?: Date | null;
  maxValue?: Date | null;
  helperText?: string;
  onChange?: (event: IChangeFieldEvent<Date | null>) => void;
  isError?: boolean;
  label?: string | ReactNode;
  name?: string;
  shouldHideControlInfo?: boolean;
}

export const Datepicker = ({
  value,
  minValue,
  maxValue,
  onChange,
  helperText,
  label,
  isError,
  shouldHideControlInfo,
  name,
}: IDatepicker) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Styled.Datepicker
        className='date-picker'
        format='DD/MM/YYYY'
        label={label}
        // For some reason typescript shows that supported types for value are only null or undefined that is incorrect.
        // To fix this we need to converted to any until we found better solution.
        /* eslint-disable @typescript-eslint/no-explicit-any */
        value={value ? (dayjs(value) as any) : undefined}
        minDate={minValue ? (dayjs(minValue) as any) : undefined}
        maxDate={maxValue ? (dayjs(maxValue) as any) : undefined}
        slots={{
          openPickerIcon: () => <Styled.CalendarIcon iconType='calendar' />,
        }}
        slotProps={{
          textField: {
            sx: {
              fontSize: '11px',
            },
          },
          day: {
            sx: {
              fontSize: '11px',

              '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main}!important`,
              },
            },
          },
          desktopPaper: {
            sx: {
              width: '320px',
              height: '335px',

              '.MuiPickersCalendarHeader-labelContainer': {
                fontSize: '14px',
              },

              '.MuiPickersYear-root': {
                height: '35px',

                button: {
                  fontSize: '12px',
                },

                '.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main}!important`,
                },
              },

              '.MuiDayCalendar-weekDayLabel': {
                fontSize: '11px',
              },
            },
          },
        }}
        // For some reason typescript shows that supported types for value are only null that is incorrect.
        // To fix this we need to converted to Dayjs manually until we found better solution.
        onChange={v =>
          onChange &&
          onChange({
            target: {
              name: name || '',
              value: v ? (v as unknown as Dayjs).toDate() : null,
            },
          })
        }
      />{' '}
      <ControlInfo
        isError={isError}
        helperText={helperText}
        isHide={shouldHideControlInfo}
      />
    </Fragment>
  );
};
