import { EBrand } from '@trader/types';
import { appConfigUtils } from '@trader/utils';

import { useWholeBusinessLogic } from './useWholeBusinessLogic';
import { useFreeScoreLevelBusinessLogic } from './useFreeScoreLevelBusinessLogic';

export enum EConditionType {
  RegistrationCompleted, // isRegistrationCompleted = false
  ScoreLevel, // isRegistrationCompleted = true but scoreLevel = LOW;
  TradingAllowed, // isTradingAllowed = false;
  DepositAllowed, // isDepositAllowed = false;
}

export type TApplyCondition = () => {
  status: 'passed' | 'failed';
  action?: () => void;
};

type TUseBusinessLogic = (conditionType: EConditionType) => {
  applyCondition: TApplyCondition;
  isFirstCaseCondition: boolean;
  isSecondCaseCondition: boolean;
  isThirdCaseCondition: boolean;
};

// FrontBroker, TradeItaly, HeroMarkets - useWholeBusinessLogic
// Markets77, TradeGlobal, TrdFx, PipsTrades, ChallengesTrades, Finexo - useFreeScoreLevelBusinessLogic

export const useBusinessLogic: TUseBusinessLogic = conditionType => {
  const rules: Record<EBrand, TApplyCondition> = {
    Markets77: useFreeScoreLevelBusinessLogic(conditionType),
    FrontBroker: useWholeBusinessLogic(conditionType),
    TradeGlobal: useFreeScoreLevelBusinessLogic(conditionType),
    TradeItaly: useWholeBusinessLogic(conditionType),
    TrdFx: useFreeScoreLevelBusinessLogic(conditionType),
    PipsTrades: useFreeScoreLevelBusinessLogic(conditionType),
    ChallengesTrades: useFreeScoreLevelBusinessLogic(conditionType),
    Finexo: useFreeScoreLevelBusinessLogic(conditionType),
    HeroMarkets: useWholeBusinessLogic(conditionType),
  };

  return {
    applyCondition: () => rules[appConfigUtils.getCurrentBrand()](),
    // TODO: (av) Do we need this conditions? Seems like they don't return from the hook.
    // isFirstCaseCondition: rules[store.app.brand]().isFirstCaseCondition,
    // isSecondCaseCondition: rules[store.app.brand]().isSecondCaseCondition,
    // isThirdCaseCondition: rules[store.app.brand]().isThirdCaseCondition,
    isFirstCaseCondition: false,
    isSecondCaseCondition: false,
    isThirdCaseCondition: false,
  };
};
