import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { TInstrumentEntity, useMst } from '@trader/store';
import { BANDS, IIBandItem } from '@trader/constants';
import { TPlaceOrderSide } from '@trader/types';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

export const Bands: React.FC = observer(() => {
  const store = useMst();
  const [activeBand, setActiveBand] = useState<string | null>(null);
  const bands = store.pages.muliBands.bandsButtons.length
    ? store.pages.muliBands.bandsButtons
    : Object.values(BANDS);

  useEffect(() => {
    return () => {
      setActiveBand(null);
    };
  }, []);

  const toggleBand = useCallback((bandId: string | null) => {
    setActiveBand(prev => (bandId === prev ? null : bandId));
  }, []);
  return (
    <Styled.Root>
      <Styled.Title>Bands</Styled.Title>
      <Styled.Box>
        {activeBand && <Styled.Blur onClick={() => setActiveBand(null)} />}
        {bands.map(band => (
          <Band
            key={band.id}
            band={band}
            activeBand={activeBand}
            toggleBand={toggleBand}
          />
        ))}
      </Styled.Box>
    </Styled.Root>
  );
});

interface IBand {
  band: IIBandItem;
  activeBand: string | null;
  toggleBand: (bandId: string | null) => void;
}

export const Band: React.FC<IBand> = observer(
  ({ band, activeBand, toggleBand }) => {
    const store = useMst();
    const { translate } = useI18next();

    const muliBands = store.pages.muliBands;

    const tradingSymbol = store.pages.trading.getInstrumentSymbolByLayout();
    const instrument = store.entities.instruments.get<TInstrumentEntity>(
      muliBands.symbol || tradingSymbol
    );

    const strip = store.pages.muliBands.strips.get(band.id);
    const stripValue = strip?.value || 0;

    const placeOrder = useCallback(
      (side: TPlaceOrderSide) => () => {
        store.pages.muliBands.createBandOrderAsync.run({
          side,
          bandId: band.id,
        });
        toggleBand(null);
      },
      []
    );

    return (
      <Styled.BandWrapper $order={band.order}>
        <Styled.Band
          disabled={!store.pages.muliBands.id}
          $isBlur={!!activeBand}
          $type={band.type}
          $isVwap={Number(band.value) === 0}
          onClick={() => toggleBand(band.id)}
        >
          {band?.title || `Band ${band.value}`}
        </Styled.Band>
        {activeBand === band.id && (
          <Styled.AbsoluteBandWrapper $order={band.order}>
            <Styled.Band
              $isVwap={Number(band.value) === 0}
              $type={band.type}
              onClick={() => toggleBand(band.id)}
            >
              {band?.title || `Band ${band.value}`}
            </Styled.Band>
            <Styled.Purchase
              disabled={stripValue > instrument.ask}
              onClick={placeOrder('Buy')}
            >
              {translate('COMMON.LABELS.BUY')}
            </Styled.Purchase>
            <Styled.Purchase
              disabled={stripValue < instrument.ask}
              onClick={placeOrder('Sell')}
            >
              {translate('COMMON.LABELS.SELL')}
            </Styled.Purchase>
          </Styled.AbsoluteBandWrapper>
        )}
      </Styled.BandWrapper>
    );
  }
);
