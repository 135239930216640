import React from 'react';

import { AppInitializer } from '@trader/containers';

import { routes } from '../routes';
import { darkTheme, lightTheme } from '../themes';

export const App: React.FC = () => (
  <AppInitializer
    darkTheme={darkTheme}
    lightTheme={lightTheme}
    routes={routes}
  />
);
