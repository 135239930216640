import { api } from '@trader/api';
import { EChallenge } from '@trader/types';

import { createThunk } from '../../utils/asyncModel';
import { getRootInstance } from '../../configureStore/configureStore';
import { challengesSchema } from './schemas';

export const getChallengesAsync = createThunk<void, void>(
  () =>
    async function getChallenges(
      this: unknown,
      _options,
      _flow
    ): Promise<void> {
      const root = getRootInstance();

      const response = await api.Challenge.getChallenges(_options);
      response.forEach(challenge => {
        if (!challenge.hierarchyType) {
          challenge.hierarchyType = EChallenge.NonAssigned;
        }
      });

      root.entities.normalizeMerge(response, challengesSchema, true);
      response.forEach(ch =>
        root.entities.tradingAccounts.update(ch.accountId, {
          challenge: ch.id,
        })
      );
    }
);
