import styled, { css } from 'styled-components';
import { Button, Typography, Wrapper } from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    margin-top: 41px;
    padding: 0 ${modalHorizontalPadding}px;

    ${theme.breakpoints.down('sm')} {
      margin-top: 6px;
    }
  `}
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  `}
`;

export const Field = styled(Wrapper)`
  ${({ theme }) => css`
    align-items: baseline;
    width: 100%;
    margin-bottom: 10px;

    ${theme.breakpoints.down('sm')} {
      & > div {
        width: 100%;
      }
    }

    > div > div:nth-child(2) {
      width: 100%;
      max-width: 240px;
    }
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-size: 14px;
    width: 150px;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `}
`;

export const Save = styled(Button)`
  ${({ theme }) => css`
    width: 190px;
    height: 50px;
    border-radius: 5px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    margin-top: 45px;

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
    }
  `}
`;
