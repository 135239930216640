import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { isLiveEnv } from '@trader/constants';

const avoidMessages = [
  'WebSocket failed to connect', // reconnecting web-sockets
  'Server returned handshake error: Handshake was canceled.', // reconnecting web-sockets
  'WebSocket closed with status code: 1006 (no reason given).', // reconnecting web-sockets
  "Cannot read properties of undefined (reading 'send')", // reconnecting web-sockets
  "undefined is not an object (evaluating 'this._transport.send')", // reconnecting web-sockets
  'this._transport is undefined', // reconnecting web-sockets
  'Failed to start the HttpConnection before stop() was called.', // reconnecting web-sockets
  'Non-Error promise rejection captured with value: Timeout', // google recaptcha https://github.com/getsentry/sentry-javascript/issues/2514
  "Cannot read properties of undefined (reading 'logging')", // google recaptcha https://github.com/getsentry/sentry-javascript/issues/2514
  'Multiple Sentry Session Replay instances are not supported', // https://github.com/getsentry/sentry/issues/47532
  'Refresh token call in not update access token', // custom error, do not need to send
  "Messaging: This browser doesn't support the API's required to use the Firebase SDK.", // Firebase SDK error, do not need to send
  'Messaging: The notification permission was not granted and blocked instead.', // Firebase SDK error, do not need to send
];

export const initSentry = () =>
  isLiveEnv &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    ignoreErrors: avoidMessages,
    beforeSend(event, hint) {
      const message = hint.originalException;
      if (message) {
        avoidMessages.forEach(m => {
          if (message.toString().includes(m)) {
            return null;
          }
        });
      }

      return event;
    },
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'https://webtrader.tradecapitalmarkets.com',
      'https://webtrader.pipstrades.com',
      'https://web2.77markets.com',
      'https://web.trade.com',
      'https://wt-challenges.trade.com',
      'https://webtrader.finexo.com',
      'https://web.it.trade.com',
      'https://webtrader.trdfx.com',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
