import React from 'react';
import { observer } from 'mobx-react-lite';

import { TAlertEntity, TModalStore, useMst } from '@trader/store';
import { ModalTitle } from '@trader/components';
import { useI18next } from '@trader/services';
import { formatByPipSize } from '@trader/utils';

import * as Styled from './styled';
import { ConfirmButton } from '../components/confirmButton';

export const DeleteAlert: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const modal: TModalStore = store.ui.modal;
  const alertId = modal.options.get('alertId');

  const alert = store.entities.alerts.get<TAlertEntity>(alertId);
  const instrument = alert?.conditions[0].instrument;
  const handleCancelOrder = async () => {
    await store.entities.alerts.deleteAlertAsync.run(alertId);
  };

  return (
    <Styled.Root>
      <ModalTitle
        bottomMargin='0'
        title={translate('COMMON.LABELS.DELETE_ALERT')}
      />
      <Styled.Info>
        <Styled.InfoLeft>
          <Styled.Symbol>{instrument?.displayName}</Styled.Symbol>
          <Styled.Description>{instrument?.description}</Styled.Description>
        </Styled.InfoLeft>
        <Styled.InfoRight>
          <Styled.Placeholder>
            {translate('COMMON.LABELS.CURRENT_PRICE')}
          </Styled.Placeholder>
          <Styled.Price>
            {instrument?.currencySymbol}
            {instrument
              ? formatByPipSize(instrument.currentPrice, instrument.pipSize)
              : '--'}
          </Styled.Price>
        </Styled.InfoRight>
      </Styled.Info>

      <ConfirmButton
        isDisabled={store.entities.alerts.deleteAlertAsync.inProgress}
        isLoading={store.entities.alerts.deleteAlertAsync.inProgress}
        onClick={handleCancelOrder}
        title={translate('COMMON.LABELS.DELETE_ALERT')}
      />
    </Styled.Root>
  );
});
