import { localStorageService } from '@trader/services';
import { isLiveEnv, LOCAL_STORAGE_KEYS } from '@trader/constants';
import { EApiType, EBrand } from '@trader/types';

class AppConfigUtils {
  getCurrentApiType() {
    return (
      (localStorageService.get(LOCAL_STORAGE_KEYS.apiType) as EApiType) ||
      (isLiveEnv ? EApiType.PRODUCTION : EApiType.STAGING)
    );
  }

  getCurrentBrand() {
    return (
      (localStorageService.get(LOCAL_STORAGE_KEYS.brand) as EBrand) ||
      import.meta.env.VITE_BRAND
    );
  }
}

export const appConfigUtils = new AppConfigUtils();
