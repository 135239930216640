import styled, { css } from 'styled-components';
import { Stack } from '@mui/material';
import { Button, Typography } from '@trader/components';

export const Wrapper = styled(Stack)`
  ${({ theme }) => css`
    margin-left: 12px;
    width: 165px;
    padding: 10px 5px 10px 17px;
    border-left: 1px solid ${theme.palette.gray.light};

    .MuiFormControl-root {
      border-radius: 8px;
      background: ${theme.palette.gray.light};
      font-size: 11px;

      fieldset {
        border: none;
      }

      input {
        font-size: 11px;
        color: ${theme.palette.primary.dark};
        box-sizing: border-box;
      }
    }
  `};
`;

export const Label = styled(Typography)`
  ${() => css`
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 7px;
  `};
`;

export const ApplyButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    font-size: 11px;
    border-radius: 5px;
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.primary.main};

    &:hover {
      background-color: ${theme.palette.primary.main}
  `};
`;
