import { Instance, types } from 'mobx-state-tree';

import {
  formatMoney,
  getAmountDigitsAfterDot,
  getBuyLossRateWithAmplitude,
  getBuyProfitRateWithAmplitude,
  getConvertedLoss,
  getConvertedProfit,
  getSellLossRateWithAmplitude,
  getSellProfitRateWithAmplitude,
  handleIndicesPipSize,
  runInAction,
} from '@trader/utils';
import { instrumentModel } from '../entities/instruments/index';
import {
  getInstrumentReferenceAsync,
  getRequiredOpenCostAsync,
  prePlaceOrderAsync,
} from './actions';
import {
  TAddProtectionType,
  TPlaceOrderSide,
  TPlaceOrderType,
} from '@trader/types';
import { getRootInstance } from '../configureStore/configureStore';

const tradingModel = types
  .model('tradingModel', {
    instrument: types.maybeNull(types.safeReference(instrumentModel)),
    side: types.frozen<TPlaceOrderSide>('Buy'),
    orderType: types.frozen<TPlaceOrderType>('Market'),
    amount: types.optional(types.string, '0'),
    price: types.optional(types.string, '0'),
    isOrderInPending: false,

    requiredMargin: types.maybeNull(types.number),
    currencyConvert: types.maybeNull(types.number),
    isRequiredOpenCostError: false,

    stopLossType: types.frozen<TAddProtectionType>('Rate'),
    takeProfitType: types.frozen<TAddProtectionType>('Rate'),
    isStopLoss: false,
    isTakeProfit: false,
    stopLossPips: types.optional(types.string, '0'),
    stopLoss: types.optional(types.string, '0'),
    takeProfit: types.optional(types.string, '0'),
    takeProfitPips: types.optional(types.string, '0'),

    profit: types.optional(types.string, '--'),
    loss: types.optional(types.string, '--'),

    getInstrumentReferenceAsync,
    prePlaceOrderAsync,
    getRequiredOpenCostAsync,
  })
  .views(state => ({
    amountDigitsAfterDot: () =>
      state.instrument
        ? getAmountDigitsAfterDot(state.instrument.minOrderSizeIncrement)
        : 0,
  }))
  .actions(state => ({
    runInAction,
    setFormField: (name: string, value: string | number | boolean) => {
      state[name] = value;
    },
    calculateProfit: (
      {
        amount,
        takeProfit,
        takeProfitType,
        currentPrice,
        openPrice,
        side,
        orderType,
      }: {
        amount: string;
        takeProfit: string;
        currentPrice: string;
        openPrice?: string;
        side: TPlaceOrderSide;
        orderType: TPlaceOrderType;
        takeProfitType: TAddProtectionType;
      },
      shouldAvoidPipsValidation = false
    ) => {
      if (!state.instrument) {
        return;
      }
      if (takeProfitType === 'Rate' || shouldAvoidPipsValidation) {
        const isPendingOrder = orderType !== 'Market';

        const pipSize = handleIndicesPipSize(
          state.instrument.pipSize,
          state.instrument?.category || undefined,
          state.instrument?.minOrderSizeIncrement
        );
        const profitRate = Number(takeProfit)
          ? takeProfit
          : side === 'Buy'
          ? getBuyProfitRateWithAmplitude(
              state.instrument.stopsLevel,
              state.instrument.tickSize,
              pipSize,
              isPendingOrder ? currentPrice : state.instrument.ask,
              state.instrument.pipSize,
              undefined
            )
          : getSellProfitRateWithAmplitude(
              state.instrument.stopsLevel,
              state.instrument.tickSize,
              pipSize,
              isPendingOrder ? currentPrice : state.instrument.bid,
              state.instrument.pipSize,
              undefined
            );

        const convertedValue = getConvertedProfit(
          Number(amount),
          profitRate,
          side,
          openPrice || isPendingOrder ? +currentPrice : state.instrument.ask,
          openPrice || isPendingOrder ? +currentPrice : state.instrument.bid,
          state.currencyConvert ?? 1
        );
        const root = getRootInstance();

        state.profit = formatMoney(convertedValue, {
          currencySymbol: root.user.tradingAccount?.currencySymbol || '',
          pipSize,
        });
      }
    },
    calculateLoss: (
      {
        amount,
        stopLoss,
        stopLossType,
        currentPrice,
        openPrice,
        side,
        orderType,
      }: {
        amount: string;
        stopLoss: string;
        currentPrice: string;
        openPrice?: string;
        side: TPlaceOrderSide;
        orderType: TPlaceOrderType;
        stopLossType: TAddProtectionType;
      },
      shouldAvoidPipsValidation = false
    ) => {
      if (!state.instrument) {
        return;
      }
      if (stopLossType === 'Rate' || shouldAvoidPipsValidation) {
        const isPendingOrder = orderType !== 'Market';

        const pipSize = handleIndicesPipSize(
          state.instrument.pipSize,
          state.instrument?.category || undefined,
          state.instrument?.minOrderSizeIncrement
        );

        const lossRate = Number(stopLoss)
          ? stopLoss
          : side === 'Buy'
          ? getBuyLossRateWithAmplitude(
              state.instrument.stopsLevel,
              state.instrument.tickSize,
              pipSize,
              isPendingOrder ? currentPrice : state.instrument.ask,
              state.instrument.pipSize,
              undefined
            )
          : getSellLossRateWithAmplitude(
              state.instrument.stopsLevel,
              state.instrument.tickSize,
              pipSize,
              isPendingOrder ? currentPrice : state.instrument.bid,
              state.instrument.pipSize,
              undefined
            );

        const convertedValue = getConvertedLoss(
          Number(amount),
          lossRate,
          side,
          openPrice || isPendingOrder ? +currentPrice : state.instrument.ask,
          openPrice || isPendingOrder ? +currentPrice : state.instrument.bid,
          state.currencyConvert ?? 1
        );

        const root = getRootInstance();

        state.loss = formatMoney(convertedValue, {
          currencySymbol: root.user.tradingAccount?.currencySymbol || '',
          pipSize,
        });
      }
    },
    updateSide: (side: TPlaceOrderSide) => {
      state.side = side;
    },
    updateOrderType: (type: TPlaceOrderType) => {
      state.orderType = type;
    },
    toggleProtectionType: (
      key: 'stopLossType' | 'takeProfitType',
      value: TAddProtectionType
    ) => {
      state[key] = value;
    },
    toggleIsProtection: (
      key: 'isTakeProfit' | 'isStopLoss',
      value: boolean
    ) => {
      state[key] = value;
    },
    clear: () => {
      state.instrument = null;
      state.requiredMargin = null;
      state.currencyConvert = null;
      state.side = 'Buy';
      state.orderType = 'Market';
      state.amount = '0';
      state.price = '0';
      state.isOrderInPending = false;
      state.isRequiredOpenCostError = false;
      state.stopLossType = 'Rate';
      state.takeProfitType = 'Rate';
      state.isStopLoss = false;
      state.isTakeProfit = false;
      state.stopLossPips = '0';
      state.stopLoss = '0';
      state.takeProfit = '0';
      state.takeProfitPips = '0';
      state.profit = '--';
    },
  }));

export const trading = types.optional(tradingModel, {});

export type TTradingInstance = Instance<typeof tradingModel>;
