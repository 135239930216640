import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import * as Styled from './styled';
import { TInstrumentEntity, useMst } from '@trader/store';
import { dateFormats, formatTableRowValueByPipSize } from '@trader/utils';
import {
  IHeadCell,
  IRenderRowItemsCallBacks,
  ScrollingList,
} from '@trader/components';
import { EOrderSide, EStripName, IBackTestingPosition } from '@trader/types';

// TODO (ok): will add localization and proper styles after we get the last design
export const ListOfTrades: React.FC = observer(() => {
  const store = useMst();
  const backTesting = store.pages.muliBands.backTesting;

  const symbol = store.entities.instruments.get<TInstrumentEntity>(
    backTesting.symbolOption?.value || ''
  );

  const cells: Array<IHeadCell> = [
    {
      id: 'side',
      label: 'Side',
      minWidth: 40,
      align: 'start',
    },
    {
      id: 'bandId',
      label: 'Band',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'profit',
      label: 'Profit',
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'openPrice',
      label: 'Open Price',
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'closePrice',
      label: 'Close Price',
      minWidth: 60,
      align: 'center',
    },
    {
      id: 'openTime',
      label: 'Open Time',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'closeTime',
      label: 'Close Time',
      minWidth: 100,
      align: 'center',
    },
  ];

  const renderRowItemsCb: IRenderRowItemsCallBacks<IBackTestingPosition> =
    useMemo(
      () => ({
        profit: item =>
          formatTableRowValueByPipSize(item.value, symbol.pipSize),
        side: item => EOrderSide[item.value as EOrderSide],
        bandId: item => EStripName[item.value as EStripName],
        openPrice: item =>
          formatTableRowValueByPipSize(item.value, symbol.pipSize),
        closePrice: item =>
          formatTableRowValueByPipSize(item.value, symbol.pipSize),
        openTime: item =>
          dateFormats(new Date(item.value as string), 'dd/mm/yyyy hh:mm:ss'),
        closeTime: item =>
          dateFormats(new Date(item.value as string), 'dd/mm/yyyy hh:mm:ss'),
      }),
      []
    );

  return (
    <Styled.Content>
      <ScrollingList<IBackTestingPosition>
        rows={backTesting.positions}
        headCells={cells}
        renderRowItemsCallBacks={renderRowItemsCb}
      />
    </Styled.Content>
  );
});
