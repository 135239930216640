import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  ExpandedContent,
  Progress,
  Tabs,
  THeightMode,
} from '@trader/components';
import { useMst } from '@trader/store';

import { ListOfTrades, PerformanceSummary } from './components';

import * as Styled from './styled';

const defaultHeight = 250;
const minHeight = 70;
const maxHeight = 600;

interface IState {
  height: number;
  isFullScreen: boolean;
}

const initialState = {
  height: defaultHeight,
  isFullScreen: false,
};

export const BackTestingContent: React.FC = () => {
  const [state, setState] = useState<IState>(initialState);

  const handleChangeState =
    (key: keyof typeof initialState) => (value: number | boolean) => {
      setState(prev => ({ ...prev, [key]: value }));
    };

  if (state.isFullScreen) {
    return (
      <Styled.Root $isFullScreen={state.isFullScreen}>
        <Styled.ToggleFullScreen
          iconType={state.isFullScreen ? 'fullScreenExit' : 'fullScreen'}
          onClick={() => handleChangeState('isFullScreen')(!state.isFullScreen)}
        />
        <Content />
      </Styled.Root>
    );
  }

  return (
    <ExpandedContent<THeightMode>
      className='backTestingExpanded'
      height={state.height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      onUpdateHeight={handleChangeState('height')}
    >
      <Styled.ToggleFullScreen
        iconType={state.isFullScreen ? 'fullScreenExit' : 'fullScreen'}
        onClick={() => handleChangeState('isFullScreen')(!state.isFullScreen)}
      />
      <Content />
    </ExpandedContent>
  );
};

const Content: React.FC = observer(() => {
  const store = useMst();
  const isPending = store.pages.muliBands.backTesting.isPending;

  const tabs = [
    {
      value: 'performance-summary',
      label: 'Performance Summary',
      children: <PerformanceSummary />,
    },
    {
      value: 'list-of-trades',
      label: 'List of Trades',
      children: <ListOfTrades />,
    },
  ];

  return (
    <Styled.Content>
      {isPending && <Progress size={20} className='progress' />}
      <Tabs marginBottom='0' tabsPosition='top' tabs={tabs} />
    </Styled.Content>
  );
});
