import React from 'react';
import { observer } from 'mobx-react-lite';

import {
  EConditionType,
  useBusinessLogic,
  useCloseModalForAlreadyDeletedInstance,
  useGetTradingDefaultFormValues,
} from '@trader/hooks';
import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';
import {
  Form,
  ModalTitle,
  Progress,
  TradingIsClosed,
  RealTimeTriggering,
} from '@trader/components';
import { useI18next } from '@trader/services';
import { calcPositionPl, calculateSpread } from '@trader/utils';
import { IInitialTradingFormValues } from '@trader/types';

import {
  IPositionMetricStore,
  TradingModalHeader,
} from '../components/tradingModalHeader';
import { BuyButton } from '../components/buyButton';
import { ConfirmButton } from '../components/confirmButton';
import { Protection } from '../createOrder/components/Protection';
import { useAddProtectionFormValidation } from './formValidation';

import * as Styled from './styled';

const AddProtection: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();
  const { validationSchema } = useAddProtectionFormValidation();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  const positionId = store.ui.modal.options.get('positionId');

  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  const takeProfit = metric?.takeProfit?.limitPrice || undefined;
  const stopLoss = metric?.stopLoss?.stopPrice || undefined;
  const amount = metric?.quantity || undefined;

  const defaultFormValues = useGetTradingDefaultFormValues({
    symbol: instrument.symbol,
    side: metric?.side,
    takeProfit,
    stopLoss,
    amount,
  });

  const { applyCondition: applyConditionThird } = useBusinessLogic(
    EConditionType.TradingAllowed
  );

  const isInvestmentAccProduct = store.user.isInvestmentAccProduct();

  const addProtection = (data: IInitialTradingFormValues) => {
    metric?.editPositionAsync.run({
      positionId,
      body: {
        stopPrice: data.isStopLoss ? +data.stopLoss : 0,
        limitPrice: data.isTakeProfit ? +data.takeProfit : 0,
        symbol: instrument.symbol,
      },
    });

    // The values will be used in socket handlers
    // to show the notification about protection correctly.
    store.ui.modal.update({
      positionId,
      isProtectionAdded: true,
    });
  };

  if (applyConditionThird().status === 'failed') {
    return (
      <TradingIsClosed
        iconTypeName='warning'
        title={translate('WARNINGS.TRADING_IS_DISABLED')}
        content={translate('WARNINGS.PLEASE_CONTACT_SUPPORT', {
          email: import.meta.env.VITE_SUPPORT_EMAIL,
        })}
        buttonContent={translate('COMMON.LABELS.PROCEED')}
        onClick={applyConditionThird()?.action}
      />
    );
  }

  if (instrument.tradingStatus === 'CLOSE_ONLY') {
    return (
      <Styled.Root>
        <ModalTitle title={translate('COMMON.LABELS.ADD_PROTECTION')} />
        <TradingIsClosed
          iconTypeName='warning'
          title={translate('WARNINGS.TRADING_LIMITED_TO_CLOSE_ONLY_POSITIONS')}
        />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <ModalTitle title={translate('COMMON.LABELS.ADD_PROTECTION')} />
      <Form<IInitialTradingFormValues>
        onSubmit={(_reser, data) => {
          addProtection(data);
        }}
        mode='all'
        defaultValues={defaultFormValues}
        validationSchema={validationSchema}
      >
        {({ formState }) => (
          <RealTimeTriggering>
            <Styled.Position>
              <TradingModalHeader<IPositionMetricStore>
                type='position'
                metric={{
                  ...metric,
                  pl: calcPositionPl(
                    metric.price,
                    metric.openPrice,
                    metric.quantity,
                    metric.exchangeRate,
                    metric.side
                  ),
                }}
              />
              {!isInvestmentAccProduct && (
                <Styled.ActionButtons>
                  <BuyButton isDisabled isAsk side='Sell' />
                  <Styled.Spread>
                    {calculateSpread({
                      ask: instrument.ask,
                      bid: instrument.bid,
                      pipSize: instrument.pipSize,
                    })}
                  </Styled.Spread>
                  <BuyButton isDisabled side='Buy' />
                </Styled.ActionButtons>
              )}

              <Protection openPrice={String(metric.openPrice)} />
              <ConfirmButton
                isDisabled={!formState.isValid}
                isLoading={metric?.editPositionAsync.inProgress}
                title={translate('COMMON.LABELS.ADD_PROTECTION')}
              />
            </Styled.Position>
          </RealTimeTriggering>
        )}
      </Form>
    </Styled.Root>
  );
});

export const AddProtectionWrapper: React.FC = observer(() => {
  const store = useMst();
  useCloseModalForAlreadyDeletedInstance('positionId');

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;
  const positionId = store.ui.modal.options.get('positionId');
  const metric =
    store.entities.positionsMetrics.get<TPositionMetricEntity>(positionId);

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  if (!metric) {
    return null;
  }

  return <AddProtection />;
});
