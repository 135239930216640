import styled, { css } from 'styled-components';
import {
  Button,
  Typography,
  Wrapper,
  Select as ComponentsSelect,
} from '@trader/components';
import { modalHorizontalPadding } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    margin-top: 30px;
    padding: 0 ${modalHorizontalPadding}px;

    ${theme.breakpoints.down('sm')} {
      margin-top: 0;
    }
  `}
`;

export const Container = styled(Wrapper)`
  ${({ theme }) => css`
    width: 100%;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-wrap: wrap;
    }
  `}
`;

export const Skin = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 55%;
    justify-content: flex-start;
    align-items: flex-start;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
export const TradingCentral = styled(Wrapper)`
  ${({ theme }) => css`
    flex-direction: column;
    width: 40%;
    justify-content: flex-start;
    align-items: flex-start;

    ${theme.breakpoints.down('sm')} {
      margin-top: 20px;
      width: 100%;
    }
  `}
`;

export const Item = styled(Wrapper)`
  ${() => css`
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  `}
`;

export const Save = styled(Button)`
  ${({ theme }) => css`
    width: 190px;
    height: 50px;
    border-radius: 5px;
    color: ${theme.palette.common.white};
    background: ${theme.palette.common.black};
    margin-top: 55px;

    ${theme.breakpoints.down('sm')} {
      margin-top: 18px;
    }
  `}
`;

export const Title = styled(Typography)`
  ${() => css`
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
  `}
`;

export const Label = styled(Typography)`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 14px;
    width: 20%;

    ${theme.breakpoints.down('sm')} {
      width: 50%;
    }
  `}
`;

export const Select = styled(ComponentsSelect)`
  ${({ theme }) => css`
    width: 70%;
    height: 46px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `}
`;
