import { Line } from 'recharts';
import {
  Circle,
  ClipPath,
  Defs,
  Ellipse,
  G,
  LinearGradient,
  Path,
  Polygon,
  Polyline,
  RadialGradient,
  Rect,
  Stop,
  Svg,
  Text,
  Tspan,
} from '@react-pdf/renderer';
import { parse } from 'svg-parser';

import { IAccountStatementBE } from '@trader/api';

import { IChartData, IAccountStatementAction } from '../../index';

export const balanceHistoryPositionsActions = [0, 1];
const componentsMap = {
  svg: Svg,
  line: Line,
  polyline: Polyline,
  polygon: Polygon,
  path: Path,
  rect: Rect,
  circle: Circle,
  ellipse: Ellipse,
  text: Text,
  tspan: Tspan,
  g: G,
  stop: Stop,
  defs: Defs,
  clipPath: ClipPath,
  lineargradient: LinearGradient,
  radialgradient: RadialGradient,
};
const statementActions: Array<IAccountStatementAction> = [
  {
    id: 0,
    description: 'Buy',
  },
  {
    id: 1,
    description: 'Sell',
  },
  {
    id: 2,
    description: 'Balance',
  },
  {
    id: 3,
    description: 'Credit',
  },
  {
    id: 4,
    description: 'Additional charges/withdrawals',
  },
  {
    id: 5,
    description: 'Correcting',
  },
  {
    id: 6,
    description: 'Bonuses',
  },
  {
    id: 7,
    description: 'Commission',
  },
  {
    id: 8,
    description: 'Daily commission',
  },
  {
    id: 9,
    description: 'Monthly commission',
  },
  {
    id: 10,
    description: 'Daily agent commission',
  },
  {
    id: 11,
    description: 'Daily agent commission',
  },
  {
    id: 12,
    description: 'Accrual of annual interest',
  },
];

export const getChartData = (
  accountStatement: IAccountStatementBE
): Array<IChartData> => {
  return accountStatement.balanceChart.map((value, index) => ({
    name: index,
    value: value,
  }));
};

export const formatDecimal = (amount: number | undefined | null) => {
  if (amount === undefined || amount === null) {
    return '--';
  }

  const franctionDigits = 2;

  return Number(amount.toFixed(franctionDigits));
};

export const getAccountStatementActionDescription = (id: number) => {
  const action = statementActions.find(t => t.id === id);

  return action ? action.description : '-';
};

const getChildren = (children, isChart) => {
  return children?.map(child => convertJsonToPdfComponent(child, isChart));
};
const convertJsonToPdfComponent = (jsonElement, isChart) => {
  if (jsonElement.type === 'element') {
    const { tagName, properties, children } = jsonElement;
    const ChildComponent = componentsMap[tagName];

    if (tagName === 'svg' && isChart) {
      return (
        <Svg width='550' height='150' viewBox='0 0 960 250'>
          {getChildren(children, isChart)}
        </Svg>
      );
    }

    if (tagName === 'line') {
      return (
        <Polyline
          points={`${properties.x1}, ${properties.y1}, ${properties.x2}, ${properties.y2}`}
          {...properties}
        >
          {getChildren(children, isChart)}
        </Polyline>
      );
    }

    if (tagName === 'tspan') {
      return (
        <Tspan
          textAnchor='end'
          x={properties.x}
          dominantBaseline='text-before-edge'
        >
          {getChildren(children, isChart)}
        </Tspan>
      );
    }

    if (
      tagName === 'text' &&
      properties.class === 'recharts-text recharts-label'
    ) {
      return '';
    }

    if (ChildComponent) {
      return (
        <ChildComponent {...properties}>
          {getChildren(children, isChart)}
        </ChildComponent>
      );
    }
  } else if (jsonElement.type === 'text') {
    return jsonElement.value;
  }
  return null;
};
export const recursivelyConvertJsonToPdfComponents = (svgContent, isChart) => {
  const svgObject = parse(svgContent);

  return svgObject.children.map(child =>
    convertJsonToPdfComponent(child, isChart)
  );
};
