import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/material';

import {
  TInstrumentEntity,
  TPositionMetricEntity,
  useMst,
} from '@trader/store';

import {
  IChartingLibraryWidget,
  IPositionLineAdapter,
} from '../../charting_library';
import { calcPositionPl, getNetPl } from '@trader/utils';
import { useGetAdapterDiff } from './useGetAdapterDiff';

interface IPosition {
  key: string;
  ref: IPositionLineAdapter | undefined;
}

const positionLineLength = 22;
const positions = new Map<string, IPosition>();

export const usePositions = (
  widget: React.MutableRefObject<IChartingLibraryWidget | null>
) => {
  const store = useMst();
  const theme = useTheme();
  const { typeColor, tooltip } = useGetAdapterDiff('Position');

  const positionsMetrics =
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();
  const strips = Array.from(store.pages.muliBands.strips.values());
  const symbol =
    store.pages.muliBands.symbol ||
    store.pages.trading.getInstrumentSymbolByLayout();

  const symbolPositionsMetrics = positionsMetrics.filter(
    metric => metric.symbol === symbol
  );

  const getInstrumentPl = (pl: number) => {
    const instrument =
      store.entities.instruments.get<TInstrumentEntity>(symbol);
    return getNetPl(pl, instrument?.currencySymbol);
  };

  const createPosition = (id: string) => {
    const position =
      store.entities.positionsMetrics.get<TPositionMetricEntity>(id);

    const currentPl = getInstrumentPl(position.pl);

    const ref = widget?.current
      ?.activeChart()
      .createPositionLine()
      .setText(currentPl.stringValue)
      .setBodyTextColor(currentPl.textColor)
      .setBodyBorderColor(typeColor(id, position.side))
      .setTooltip(tooltip(id))
      .setLineColor(typeColor(id, position.side))
      .setBodyBackgroundColor(theme.palette.white.main)
      .setLineLength(positionLineLength)
      .setQuantity(`${position?.side}: ${position?.quantity}`)
      .setQuantityBackgroundColor(theme.palette.tab.light)
      .setQuantityBorderColor(theme.palette.tab.light)
      .setPrice(position?.openPrice);

    positions.set(id, {
      ...positions.get(id),
      key: id,
      ref,
    });
  };

  const clearPositions = useCallback(() => {
    positions.clear();
  }, []);

  useEffect(() => {
    widget.current?.onChartReady(() => {
      if (!strips.length) {
        return;
      }

      symbolPositionsMetrics.forEach(metric => {
        if (!metric) {
          return;
        }

        if (!positions.get(metric.positionId)) {
          createPosition(metric.positionId);
        } else {
          const pl = calcPositionPl(
            metric.price,
            metric.openPrice,
            metric.quantity,
            metric.exchangeRate,
            metric.side
          );

          const currentPl = getInstrumentPl(pl);
          const positionRef = positions.get(metric.positionId)?.ref;

          positionRef?.setText(currentPl.stringValue);
          positionRef?.setBodyTextColor(currentPl.textColor);
          positionRef?.setTooltip(tooltip(metric.positionId));
          positionRef?.setBodyBorderColor(
            typeColor(metric.positionId, metric.side)
          );
          positionRef?.setLineColor(typeColor(metric.positionId, metric.side));
        }
      });
    });
  }, [JSON.stringify(strips), JSON.stringify(positionsMetrics)]);

  // delete position
  useEffect(() => {
    Array.from(positions.values()).forEach((position: IPosition) => {
      const matchedPosition =
        store.entities.positionsMetrics.get<TPositionMetricEntity>(
          position.key
        );
      if (!matchedPosition) {
        positions.get(position.key)?.ref?.remove();
        positions.delete(position.key);
      }
    });
  }, [positionsMetrics.length]);

  return {
    clearPositions,
  };
};
