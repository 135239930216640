import styled, { css } from 'styled-components';

import { Wrapper } from '@trader/components';
import { layoutChildrenRadius } from '@trader/constants';

export const Root = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
  `};
`;

export const Asset = styled(Wrapper)`
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `};
`;

export const Dashboard = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: ${layoutChildrenRadius}px;
  `};
`;
