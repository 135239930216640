import { useEffect, useState } from 'react';

import { IGetExchangeRateInstrumentsBody } from '@trader/api';
import { getAccountTypeForConnection, productId } from '@trader/constants';
import { IMessage } from '@trader/types';
import {
  TPositionMetricEntity,
  TTradingAccountEntity,
  TExchangeRateInstrumentsFE,
  useMst,
} from '@trader/store';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

type TUpdatedPositions = {
  positions: { positionId: string; conversionRate: number }[];
  platformLogin: string;
};

interface IParams {
  hub?: EConnectionHub;
  subscriber?: EConnectionHubSubscriber;
  account?: TTradingAccountEntity;
  onUpdate?: (updatedPositions: TUpdatedPositions) => void;
}

export const usePositionsExchangeRate = (params?: IParams) => {
  const store = useMst();

  const [positionsExchangeRate, setPositionsExchangeRate] =
    useState<TExchangeRateInstrumentsFE>([]);

  const { subscribe, unsubscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: params?.hub || EConnectionHub.Positions,
    subscriber:
      params?.subscriber || EConnectionHubSubscriber.PositionsExchangeRate,
    onMessage: handleExchangeRateUpdated,
    invokedName: 'OnQuote',
  });

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = params?.account || store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const portfolioCurrency = activeTradingAccount?.currency || '';
  const positions =
    params?.account?.positions ||
    store.entities.positionsMetrics.getAll<TPositionMetricEntity>();

  const fetchPositionsExchangeRate = async () => {
    const body: IGetExchangeRateInstrumentsBody[] = [];

    for (const position of positions) {
      const isPortfolioCurrency = position.currency === portfolioCurrency;
      const isSymbolAdded = !!body.find(i => i.symbol === position.symbol);
      const isSymbolRateFetched = !!positionsExchangeRate.find(
        ers => ers.positionSymbol === position.symbol
      );

      if (!isPortfolioCurrency && !isSymbolAdded && !isSymbolRateFetched) {
        body.push({
          currencyBase: portfolioCurrency,
          calcCurrency: position.currency,
          symbol: position.symbol,
        });
      }
    }

    if (body.length > 0) {
      const resp =
        await store.entities.instruments.getExchangeRateInstrumentsAsync.run(
          body
        );
      resp && setPositionsExchangeRate(prev => [...prev, ...resp]);
    }
  };

  function handleExchangeRateUpdated(message: IMessage) {
    const positionExchangeRate = positionsExchangeRate.find(
      per => per.rateSymbol === message.s
    );
    const positionSymbolToUpdate = positionExchangeRate?.positionSymbol;
    const isReversed = positionExchangeRate?.isReversed;
    const updatedPositions: TUpdatedPositions['positions'] = [];

    for (const position of positions) {
      if (position.symbol === positionSymbolToUpdate) {
        const exchangeRateValue =
          position.side === 'Buy' ? message.b : message.a;
        const conversionRate = isReversed
          ? 1 / exchangeRateValue
          : exchangeRateValue;

        if (params?.onUpdate) {
          updatedPositions.push({
            positionId: position.positionId,
            conversionRate,
          });
        } else {
          store.entities.positionsMetrics.update(position.positionId, {
            exchangeRate: conversionRate,
          });
        }
      }
    }

    params?.onUpdate &&
      params?.onUpdate({
        positions: updatedPositions,
        platformLogin: activeTradingAccount?.platformLogin || '',
      });
  }

  /**
   * Handle fetch position exchange rates.
   */
  useEffect(() => {
    if (
      isAuth &&
      portfolioCurrency &&
      platformLogin &&
      positions &&
      positions.length > 0
    ) {
      fetchPositionsExchangeRate();
    }
  }, [(positions || []).length, portfolioCurrency, platformLogin]);

  /**
   * Handle subscribe on positions exchange rates.
   */
  useEffect(() => {
    if (positionsExchangeRate.length > 0 && platformLogin && accountType) {
      const positionSymbols = positionsExchangeRate.map(per => per.rateSymbol);

      subscribe(async connection => {
        await connection?.send(
          'SubscribeToQuotes',
          positionSymbols,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });

      return () => {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromQuotes',
            positionSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      };
    }
  }, [positionsExchangeRate.length, platformLogin, idToken]);
};
