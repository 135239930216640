import { observer } from 'mobx-react-lite';
import {
  FieldValues,
  Control,
  Path,
  ControllerProps,
  Controller as RHFController,
} from 'react-hook-form';

import { createDynamicComponent } from '@trader/utils';

import { TextField, ITextField } from '../../textField';
import { Switcher, ISwitcher } from '../../switcher';
import { Autocomplete, IAutocomplete } from '../../autocomplete';
import { Checkbox, ICheckbox } from '../../checkbox';
import { RadioGroup, IRadioGroup } from '../../radioButton';
import { IOrderInput, OrderInput } from '../../orderInput';
import { IDatepicker, Datepicker } from '../../datepicker';

type TTextType = 'textField';
type TPasswordType = 'passwordField';
type TIOrderInputType = 'orderInput';
type TSwitcherType = 'switcherField';
type TAutocompleteType = 'autocompleteField';
type TRadioGroupType = 'radioGroupField';
type TCheckboxType = 'checkboxField';
type TDatePickerType = 'datePickerField';

interface ICommonProps<TFormValues extends FieldValues> {
  control?: Control<TFormValues>;
  name: Path<TFormValues>;
  onCustomChange?: (event) => void;
  isFocus?: boolean;
}

interface IText<TFormValues extends FieldValues>
  extends Omit<ITextField, 'name'>,
    ICommonProps<TFormValues> {
  type: TTextType;
}

interface IDatePicker<TFormValues extends FieldValues>
  extends Omit<IDatepicker, 'name'>,
    ICommonProps<TFormValues> {
  type: TDatePickerType;
}

interface IPassword<TFormValues extends FieldValues>
  extends Omit<ITextField, 'name'>,
    ICommonProps<TFormValues> {
  type: TPasswordType;
}

interface IOrder<TFormValues extends FieldValues>
  extends Omit<IOrderInput, 'name'>,
    ICommonProps<TFormValues> {
  type: TIOrderInputType;
}

interface ISwitcherProps<TFormValues extends FieldValues>
  extends Omit<ISwitcher, 'name'>,
    ICommonProps<TFormValues> {
  type: TSwitcherType;
}

interface IAutocompleteProps<TFormValues extends FieldValues>
  extends Omit<IAutocomplete, 'name'>,
    ICommonProps<TFormValues> {
  type: TAutocompleteType;
}

interface IRadioGroupProps<TFormValues extends FieldValues>
  extends Omit<IRadioGroup, 'name'>,
    ICommonProps<TFormValues> {
  type: TRadioGroupType;
}

interface ICheckboxProps<TFormValues extends FieldValues>
  extends Omit<ICheckbox, 'name'>,
    ICommonProps<TFormValues> {
  type: TCheckboxType;
}

type TFormFiled<T extends FieldValues> =
  | IText<T>
  | IPassword<T>
  | ISwitcherProps<T>
  | IAutocompleteProps<T>
  | IRadioGroupProps<T>
  | IOrder<T>
  | IDatePicker<T>
  | ICheckboxProps<T>;

export const Controller = observer(
  <TFormValues extends FieldValues>({
    type,
    control,
    onCustomChange,
    isFocus,
    ...rest
  }: TFormFiled<TFormValues>) => {
    const render: ControllerProps<TFormValues, Path<TFormValues>>['render'] = ({
      field,
      fieldState: { error },
    }) => {
      const text = rest.helperText || error?.message;
      const fieldProps = {
        ...field,
        helperText: text,
        isError: !!error?.message,
      };

      const handleChange = event => {
        field.onChange(event);
        onCustomChange && onCustomChange(event);
      };

      const handleFocus = () => {
        field.onBlur();
      };

      const components = {
        textField: TextField,
        passwordField: TextField,
        switcherField: Switcher,
        autocompleteField: Autocomplete,
        radioGroupField: RadioGroup,
        checkboxField: Checkbox,
        orderInput: OrderInput,
        datePickerField: Datepicker,
      };
      const props = {
        ...fieldProps,
        ...rest,
        onChange: handleChange,
        onFocus: isFocus ? handleFocus : undefined,
      };
      if (type === 'checkboxField') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.checked = props.value as boolean;
      }
      return createDynamicComponent(components[type], props);
    };

    return (
      <RHFController<TFormValues, Path<TFormValues>>
        control={control}
        name={rest.name}
        render={render}
      />
    );
  }
);
