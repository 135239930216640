import { TPlaceOrderSide } from '@trader/types';
import { fixTotalValue } from '@trader/utils';

const hundred = 100;

export const calcPositionPl = (
  currentPrice: number,
  openPrice: number,
  quantity: number,
  exchangeRate: number,
  side: TPlaceOrderSide
) => {
  if (side === 'Buy') {
    return (currentPrice - openPrice) * quantity * exchangeRate;
  }
  return (openPrice - currentPrice) * quantity * exchangeRate;
};

// The optional params needs here to match two interfaces.
// We need to refactor to keep the same naming convention.
// TODO: (av) Refactor the naming to remove the uneeded optional params here.
type TPosition = {
  price?: number;
  currentPrice?: number;
  openPrice: number;
  quantity: number;
  exchangeRate?: number;
  conversionRate?: number;
  side: TPlaceOrderSide;
  swap: number;
};
export const calcAllPositionsPl = (positions: TPosition[]) => {
  if (!positions.length) {
    return 0;
  }

  return positions.reduce((acc, curr) => {
    const value =
      curr.swap +
      calcPositionPl(
        curr.price || curr.currentPrice || 0,
        curr.openPrice,
        curr.quantity,
        curr.exchangeRate || curr.conversionRate || 0,
        curr.side
      );
    return acc + value;
  }, 0);
};

export const calcEquity = (allPl: number, balance: number, credit: number) => {
  return allPl + balance + credit;
};

export const calcFreeMargin = (equity: number, usedMargin: number) => {
  return equity - usedMargin;
};

export const calcMarginLevel = (equity: number, usedMargin: number) => {
  return (equity / usedMargin) * hundred;
};

export const formatAccountCurrencyValue = (
  value: number | null | undefined,
  currencySymbol: string
) => {
  if (!value || !Number.isFinite(value)) {
    return '--';
  }

  return `${currencySymbol}${value.toFixed(fixTotalValue)}`;
};

export const formatAccountPercentageValue = (
  value: number | null | undefined
) => {
  if (!value || !Number.isFinite(value)) {
    return '--';
  }

  return `${value.toFixed(fixTotalValue)}%`;
};
