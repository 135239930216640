import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { EChallenge } from '@trader/types';
import { useI18next } from '@trader/services';
import { TTradingAccountEntity, useMst } from '@trader/store';
import { GoalReached, Icon } from '@trader/components';
import {
  hasCopierFunctionality,
  isChallengeAccountType,
} from '@trader/constants';
import {
  calcAllPositionsPl,
  getGoalReached,
  getOverAllPl,
} from '@trader/utils';

import * as Styled from './styled';

interface IBottomItemPart {
  tradingAccount: TTradingAccountEntity;
}

export const BottomItemPart: React.FC<IBottomItemPart> = observer(
  ({ tradingAccount }) => {
    const { translate } = useI18next();

    const store = useMst();
    const challenge = store.user.getAccountChallenge(tradingAccount.tradingId);
    // temporary hide remainingTime (it's customer needs)
    // const remainingTime = store.user.getAccountChallenge(
    //   tradingAccount.tradingId
    // )?.remainingTime;

    const renderIconAccountType = useMemo(() => {
      if (hasCopierFunctionality) {
        const iconType =
          challenge?.hierarchyType === EChallenge.Master
            ? 'accountTypeMaster'
            : challenge?.hierarchyType === EChallenge.Slave
            ? 'accountTypeSlave'
            : null;

        return iconType && <Icon iconType={iconType} />;
      }
      return null;
    }, []);

    if (isChallengeAccountType) {
      const goalReached =
        challenge && tradingAccount.portfolio
          ? getGoalReached(
              challenge.profitTarget,
              getOverAllPl(
                tradingAccount.portfolio.balance,
                challenge.challengeAmount,
                calcAllPositionsPl(tradingAccount.positions)
              ),
              challenge.challengeAmount
            )
          : 0;

      return (
        <Styled.ChallengeBody>
          <span>ID: {challenge?.accountId}</span>
          <div>
            {renderIconAccountType}
            {/*<RemainingTime value={remainingTime} />*/}
            <GoalReached value={goalReached} />
          </div>
        </Styled.ChallengeBody>
      );
    }

    return (
      <Styled.Content>
        {tradingAccount.isManagedMaster
          ? translate('COMMON.LABELS.MASTER_ACCOUNT')
          : tradingAccount.product}{' '}
        -{' '}
        {translate(`COMMON.LABELS.${tradingAccount.accountType.toUpperCase()}`)}{' '}
        - {tradingAccount.currency}
        <span>{renderIconAccountType}</span>
      </Styled.Content>
    );
  }
);
