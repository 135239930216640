import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { formatByPipSize, getMaxDigitWithDot } from '@trader/utils';
import {
  TAlertEntity,
  TInstrumentEntity,
  TModalStore,
  useMst,
} from '@trader/store';
import { ModalTitle, OrderInput, Progress } from '@trader/components';
import { defaultIcon, medium } from '@trader/constants';
import { useI18next } from '@trader/services';
import { ICreateAlertBody } from '@trader/api';

import { ConfirmButton } from '../components/confirmButton';

import * as Styled from './styled';

const CreateAlert: React.FC = observer(() => {
  const store = useMst();
  const { translate } = useI18next();

  const modal: TModalStore = store.ui.modal;
  const alertId = modal.options.get('alertId');

  const instrument = store.trading.instrument as TInstrumentEntity;

  const [state, setState] = useState({
    targetPrice: instrument.ask,
    type: 'Above',
    error: '',
    isCreated: false,
    isEdited: false,
  });

  useEffect(() => {
    const alert = store.entities.alerts.get<TAlertEntity>(alertId);
    if (alert) {
      setState({
        targetPrice: alert
          ? Number(alert.conditions[0].rightExpression.parameters.Number)
          : instrument.ask,
        type:
          alert.conditions[0].compareType === 'BiggerThen' ? 'Above' : 'Below',
        error: '',
        isCreated: false,
        isEdited: false,
      });
    }
  }, [instrument.symbol]);

  useEffect(() => {
    const fixedPrice = formatByPipSize(instrument.ask, instrument.pipSize);
    const currency = instrument.currencySymbol;
    const isComparePrice = Number(state.targetPrice) <= Number(instrument.ask);

    if (state.type === 'Below') {
      setState(prev =>
        isComparePrice
          ? { ...prev, error: '' }
          : {
              ...prev,
              error: translate('COMMON.ERRORS.SHOULD_BE_LESS_THAN', {
                currency: currency,
                amount: fixedPrice,
              }),
            }
      );
    } else if (state.type === 'Above') {
      setState(prev =>
        isComparePrice
          ? {
              ...prev,
              error: translate('COMMON.ERRORS.SHOULD_BE_BIGGER_THAN', {
                currency: currency,
                amount: fixedPrice,
              }),
            }
          : { ...prev, error: '' }
      );
    }
  }, [state.targetPrice, state.type, instrument.ask]);

  const checkLimit = (type: 'min' | 'max'): number => {
    if (type === 'min') {
      return state.type === 'Bellow' ? 1 : instrument.ask;
    }
    return state.type === 'Bellow' ? instrument.ask : Infinity;
  };

  const handleChangeTargetPrice = (v: string) => {
    setState(prev => ({ ...prev, targetPrice: +v, isEdited: true }));
  };

  const handleCreatePriceAlert = () => {
    const body: ICreateAlertBody = {
      name: new Date().toISOString(),
      conditions: [
        {
          compareType: state.type === 'Above' ? 'BiggerThen' : 'LessThen',
          leftExpression: {
            indicatorType: 'Price',
            parameters: {},
          },
          rightExpression: {
            indicatorType: 'Number',
            parameters: {
              Number: state.targetPrice.toString(),
            },
          },
          instrument: {
            id: instrument.id,
          },
        },
      ],
      autoStart: true,
    };
    alertId
      ? store.entities.alerts.editAlertAsync.run({ ...body, id: alertId })
      : store.entities.alerts.createAlertAsync.run(body);
    setState(prev => ({ ...prev, isCreated: true }));
  };

  if (!instrument.symbol) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return (
    <Styled.Root>
      <Styled.Main>
        <ModalTitle
          bottomMargin='0'
          title={
            alertId
              ? translate('COMMON.LABELS.EDIT_ALERT')
              : translate('COMMON.LABELS.CREATE_NEW_ALERT')
          }
        />
        <Styled.Info>
          <Styled.InfoLeft>
            <img
              src={instrument?.iconUrl || defaultIcon}
              alt='instrument icon'
              width={medium}
              height={medium}
            />
            <Styled.SymbolInfo>
              <Styled.Symbol>{instrument.symbol}</Styled.Symbol>
              <Styled.Description>{instrument.description}</Styled.Description>
            </Styled.SymbolInfo>
          </Styled.InfoLeft>
          <Styled.InfoRight>
            <Styled.Placeholder>
              {translate('COMMON.LABELS.CURRENT_PRICE')}
            </Styled.Placeholder>
            <Styled.Price>
              {instrument.currencySymbol}
              {instrument.ask}
            </Styled.Price>
          </Styled.InfoRight>
        </Styled.Info>
        <Styled.Buttons>
          <Styled.ButtonType
            $isSelected={state.type === 'Above'}
            onClick={() => setState(prev => ({ ...prev, type: 'Above' }))}
          >
            {translate('COMMON.LABELS.ABOVE')}
          </Styled.ButtonType>
          <Styled.ButtonType
            $isSelected={state.type === 'Below'}
            onClick={() => setState(prev => ({ ...prev, type: 'Below' }))}
          >
            {translate('COMMON.LABELS.BELOW')}
          </Styled.ButtonType>
        </Styled.Buttons>
        <Styled.Input>
          <Styled.InputLabel>
            {translate('COMMON.LABELS.TARGET_PRICE')}
          </Styled.InputLabel>
          <OrderInput
            disabled={false}
            value={state.targetPrice.toString()}
            step={instrument.pipSize}
            onChange={event => handleChangeTargetPrice(event.target.value)}
            fixDigitAfterDot={getMaxDigitWithDot(instrument.pipSize)}
            minValue={checkLimit('min')}
            maxValue={checkLimit('max')}
            helperText={state.isEdited ? state.error : ''}
            isError={state.isEdited ? !!state.error : false}
          />
        </Styled.Input>
        <ConfirmButton
          isDisabled={
            !state.isEdited ||
            !!state.error ||
            store.entities.alerts.editAlertAsync.inProgress ||
            store.entities.alerts.createAlertAsync.inProgress
          }
          isLoading={
            store.entities.alerts.editAlertAsync.inProgress ||
            store.entities.alerts.createAlertAsync.inProgress
          }
          onClick={handleCreatePriceAlert}
          title={
            alertId
              ? translate('COMMON.LABELS.EDIT_ALERT')
              : translate('COMMON.LABELS.CREATE_ALERT')
          }
        />
      </Styled.Main>
    </Styled.Root>
  );
});

export const CreateAlertWrapper: React.FC = observer(() => {
  const store = useMst();

  const trading = store.trading;
  const instrument = trading.instrument as TInstrumentEntity;

  if (!instrument) {
    return (
      <Styled.Root>
        <Progress />
      </Styled.Root>
    );
  }

  return <CreateAlert />;
});
