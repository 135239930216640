import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { layoutMargin } from '@trader/constants';

export const Content = styled(Wrapper)`
  ${() => css`
    flex-direction: column;
    padding: ${layoutMargin}px;
  `};
`;
