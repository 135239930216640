import { useCallback } from 'react';
import i18next from 'i18next';
import I18nHttpBackend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

import { LOCAL_STORAGE_KEYS } from '@trader/constants';
import { TOptions, IOption } from '@trader/types';

import { localStorageService } from '../localStorage/index';

import en from './locales/en/translation.json';
import es from './locales/es/translation.json';
import it from './locales/it/translation.json';
import de from './locales/de/translation.json';

const defaultLng = 'en';

const languageOptions: TOptions = [
  {
    title: 'English',
    value: 'en',
  },
  {
    title: 'Spanish',
    value: 'es',
  },
  {
    title: 'Italian',
    value: 'it',
  },
  {
    title: 'German',
    value: 'de',
  },
];

export const LANGUAGES = {
  en: { name: 'English', key: 'en', resource: en },
  es: { name: 'Spanish', key: 'es', resource: es },
  it: { name: 'Italian', key: 'it', resource: it },
  de: { name: 'German', key: 'de', resource: de },
} as const;

export type TLanguageKey = keyof typeof LANGUAGES;
type TJson = typeof en;

i18next
  .use(I18nHttpBackend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: Object.values(LANGUAGES).map(l => l.key),
    interpolation: {
      escapeValue: false,
    },
  });

export const getLanguageCode = (): string => {
  const languageOption = getLanguageOption();

  return languageOption?.value || defaultLng;
};

export const getLanguageOption = (): IOption => {
  const language =
    (localStorageService.get(LOCAL_STORAGE_KEYS.language) as TLanguageKey) ||
    defaultLng;

  return (
    languageOptions.filter(o => o.value === language)[0] ?? languageOptions[0]
  );
};

const getBrandLanguages = (): string[] => {
  return import.meta.env.VITE_LANGUAGES.split(',');
};

export const getLanguageOptions = (): TOptions => {
  const options: TOptions = [];
  const brandLanguages = getBrandLanguages();

  languageOptions.forEach(option => {
    if (brandLanguages.includes(option.value)) {
      options.push(option);
    }
  });

  return options;
};

// TODO: add loadTranslationFromServer function when it will be ready on BE side
// const loadTranslationFromServer = async (lng: string): Promise<TJson> => {
//   try {
//     // TODO: add https request to fetch language
//     const res = await fetch(`/src/${lng}.json`);
//     return await res.json();
//   } catch (e) {
//     throw new Error('error');
//   }
// };

async function handleTranslate(lng?: string): Promise<void> {
  const languageCode: string = lng || getLanguageCode();

  try {
    i18next.addResourceBundle(
      languageCode,
      'translation',
      LANGUAGES[languageCode].resource
    );
  } catch (e) {
    i18next.addResourceBundle(
      languageCode,
      'translation',
      checkResource(languageCode)
    );
  } finally {
    await i18next.changeLanguage(languageCode);
  }

  // TODO: add this function when it will be ready on BE side
  // i18next.addResourceBundle(
  //   languageCode,
  //   'translation',
  //   await loadTranslationFromServer(languageCode)
  // );
}

const checkResource = (lng: string): TJson =>
  LANGUAGES[lng] ? LANGUAGES[lng].resource : en;

handleTranslate();

export const useI18next = () => {
  const { t } = useTranslation();

  const currentLng = getLanguageCode() as TLanguageKey;
  const changeLng = useCallback(async (lng: string) => {
    const language = localStorageService.get(
      LOCAL_STORAGE_KEYS.language
    ) as TLanguageKey;

    if (lng !== language) {
      localStorageService.set(LOCAL_STORAGE_KEYS.language, lng);
      await handleTranslate(lng);
    }
  }, []);

  return { currentLng, translate: t, changeLng };
};

export default i18next;
