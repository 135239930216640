import { useEffect } from 'react';

import { TAlertEntity, useMst } from '@trader/store';
import { getAccountTypeForConnection, productId } from '@trader/constants';
import { getSpreadDifference } from '@trader/utils';
import { IMessage } from '@trader/types';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

export const usePriceAlertsCurrentPrice = () => {
  const store = useMst();

  const { subscribe, unsubscribe } = useHub<IMessage>({
    url: '/v4/quotes',
    hub: EConnectionHub.PriceAlerts,
    subscriber: EConnectionHubSubscriber.PriceAlertsCurrentPrice,
    onMessage: handlePriceAlertCurrentPriceUpdated,
    invokedName: 'OnQuote',
  });

  const idToken = store.auth.tokens.idToken;
  const isAuth = store.auth.isAuth;
  const activeTradingAccount = store.user.tradingAccount;
  const platformLogin = activeTradingAccount?.platformLogin;
  const accountType = activeTradingAccount?.accountType;
  const product = store.user.getAccountProduct();

  const priceAlerts = store.entities.alerts.getAll<TAlertEntity>();

  function handlePriceAlertCurrentPriceUpdated(message: IMessage) {
    const symbolPriceAlerts = priceAlerts.filter(
      pa => pa.conditions[0].instrument.symbol === message.s
    );
    if (symbolPriceAlerts.length > 0) {
      for (const priceAlert of symbolPriceAlerts) {
        const { ask } = getSpreadDifference(
          priceAlert.conditions[0].instrument.spreadDiff,
          message.a,
          message.b
        );
        priceAlert.runInAction(() => {
          priceAlert.conditions[0].instrument.currentPrice = ask;
        });
      }
    }
  }

  /**
   * Handle subscribe on price alerts symbols.
   */
  useEffect(() => {
    if (priceAlerts.length > 0 && isAuth && platformLogin && accountType) {
      const priceAlertsSymbols = priceAlerts.map(
        pa => pa.conditions[0].instrument.symbol
      );
      const uniqueSymbols = [...new Set(priceAlertsSymbols)];

      subscribe(async connection => {
        await connection?.send(
          'SubscribeOnQuotes',
          uniqueSymbols,
          1,
          productId[product],
          platformLogin,
          getAccountTypeForConnection[accountType]
        );
      });

      return () => {
        unsubscribe(async connection => {
          await connection?.send(
            'UnsubscribeFromQuotes',
            uniqueSymbols,
            1,
            productId[product],
            platformLogin,
            getAccountTypeForConnection[accountType]
          );
        });
      };
    }
  }, [priceAlerts.length, platformLogin, idToken]);
};
