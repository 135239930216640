import { Instance, types } from 'mobx-state-tree';

import { runInAction } from '@trader/utils';

import { createCollectionModel } from '../../utils/collectionModel';
import {
  createAlertAsync,
  deleteAlertAsync,
  editAlertAsync,
  getAlertsAsync,
  getAlertByIdAsync,
} from './actions';

const conditionModel = types.model('conditionModel', {
  compareType: types.string,
  instrument: types.model('', {
    ask: types.number,
    bid: types.number,
    category: types.string,
    currency: types.string,
    currencySymbol: types.string,
    currentPrice: types.number,
    spreadDiff: types.number,
    description: types.string,
    displayName: types.string,
    iconUrl: types.maybeNull(types.string),
    id: types.number,
    pipSize: types.number,
    symbol: types.string,
  }),
  leftExpression: types.model('', {
    indicatorType: types.string,
    parameters: types.model('', {
      Number: types.optional(types.string, ''),
    }),
  }),
  rightExpression: types.model('', {
    indicatorType: types.string,
    parameters: types.model('', {
      Number: types.optional(types.string, ''),
    }),
  }),
});

export const alertModel = types
  .model('alertModel', {
    id: types.identifierNumber,
    status: types.string,
    reminderTitle: types.string,
    reminderBody: types.string,
    name: types.string,
    endTime: types.maybeNull(types.string),
    conditions: types.array(conditionModel),
  })
  .actions(() => ({
    runInAction,
  }));

interface IAlertsAsync {
  getAlertsAsync: typeof getAlertsAsync;
  getAlertByIdAsync: typeof getAlertByIdAsync;
  createAlertAsync: typeof createAlertAsync;
  editAlertAsync: typeof editAlertAsync;
  deleteAlertAsync: typeof deleteAlertAsync;
}

export const alerts = createCollectionModel<typeof alertModel, IAlertsAsync>(
  alertModel,
  {
    getAlertsAsync,
    getAlertByIdAsync,
    createAlertAsync,
    editAlertAsync,
    deleteAlertAsync,
  }
);

export type TAlertEntity = Instance<typeof alertModel>;
export type TAlertsEntity = Instance<typeof alerts>;
