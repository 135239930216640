import { FC } from 'react';
import { PDFDownloadLink, View } from '@react-pdf/renderer';

import { useI18next } from '@trader/services';

import { recursivelyConvertJsonToPdfComponents } from '../../utils';
import { IAccountStatementHtmlReport } from '../htmlReport';
import { PdfReport } from '../pdfReport';
import { styles } from './styled';

export const DownloadReportButton: FC<IAccountStatementHtmlReport> = ({
  ...pdfReportProps
}) => {
  const { translate } = useI18next();

  return (
    <PDFDownloadLink
      style={styles.confirmButton}
      document={<PdfReport {...pdfReportProps} logo={<Logo />} />}
      fileName='Account-Statement.pdf'
    >
      {({ loading }) =>
        loading
          ? translate('COMMON.LABELS.GENERATING_DOCUMENT')
          : translate('COMMON.LABELS.DOWNLOAD_PDF')
      }
    </PDFDownloadLink>
  );
};

const Logo = () => {
  const logo = document.getElementById('logo');

  if (logo) {
    const logoHtml = logo.innerHTML;
    const sv = recursivelyConvertJsonToPdfComponents(logoHtml, false);

    return sv;
  }

  return <View></View>;
};
