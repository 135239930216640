import { observer } from 'mobx-react-lite';

import { Icon } from '@trader/components';
import { useMst } from '@trader/store';
import { useNavigation } from '@trader/hooks';
import { NAVIGATE_TO } from '@trader/constants';
import { appConfigUtils } from '@trader/utils';

import * as Styled from './styled';

export const Logotype = observer(() => {
  const store = useMst();
  const { navigateTo } = useNavigation();

  const defaultBrand = import.meta.env.VITE_BRAND;
  const selectedBrand = appConfigUtils.getCurrentBrand();

  const handleLogoClick = () => {
    navigateTo(NAVIGATE_TO.main.dashboard);
  };

  return (
    <Styled.Root onClick={handleLogoClick}>
      {/* It can be not equal only in case if we are in dev mode. */}
      {defaultBrand === selectedBrand ? (
        <Icon iconType={store.app.getAppIconBasedOnTheme()} />
      ) : (
        <div>
          <span style={{ fontWeight: 'bold' }}>CURRENT BRAND:</span>{' '}
          {selectedBrand}
        </div>
      )}
    </Styled.Root>
  );
});
