import { IOption, TOptions } from '@trader/types';

export const convertFromOption = <T extends string | Array<string> = string>(
  options: Array<IOption>,
  to?: 'string' | 'array',
  returnedKey?: keyof IOption
): T => {
  const convertTo = to || 'string';
  const key = returnedKey || 'value';

  const result =
    convertTo === 'array'
      ? options.map(option => option[key] || '')
      : options[0][key] || '';

  return result as T;
};

export const convertToOption = (value: string | Array<string>): TOptions => {
  let options: TOptions = [];
  if (typeof value === 'string') {
    options = [{ value, title: value }];
  }
  if (typeof value === 'object') {
    options = value.map(v => ({
      title: v,
      value: v,
    }));
  }
  return options;
};
