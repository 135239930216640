import { IAnyStateTreeNode } from 'mobx-state-tree';

import { maxAmountPositionsToPersist } from '@trader/constants';

import { createPersist, rehydrate } from './createPersist';
import { IRootStoreInstance } from '../../configureStore/configureStore';

export interface IStoresPersist {
  store: IAnyStateTreeNode;
  key: string;
  whiteList?: string[];
  amountOfEntitiesToSave?: number;
}

export const persist = (rootStore: IRootStoreInstance): void => {
  const storesPersist: Array<IStoresPersist> = [
    {
      store: rootStore.entities.instruments,
      key: 'instruments',
      amountOfEntitiesToSave: 30,
    },
    {
      store: rootStore.entities.categories,
      key: 'categories',
      amountOfEntitiesToSave: 20,
    },
    {
      store: rootStore.entities.positionsMetrics,
      key: 'positionsMetrics',
      amountOfEntitiesToSave: maxAmountPositionsToPersist,
    },
    {
      store: rootStore.entities.positionsHistory,
      key: 'positionsHistory',
      amountOfEntitiesToSave: 10,
    },
    {
      store: rootStore.entities.ordersMetrics,
      key: 'ordersMetrics',
      amountOfEntitiesToSave: 10,
    },
    {
      store: rootStore.entities.tradingAccounts,
      key: 'tradingAccounts',
      whiteList: ['collection'],
    },
    {
      store: rootStore.entities.challenges,
      key: 'challenges',
    },
    {
      store: rootStore.pages.trading,
      key: 'trading',
      whiteList: [
        'instrumentsAmount',
        'layout',
        'selectedCategory',
        'selectedSubCategory',
        'assetsView',
        'layoutView',
      ],
    },
    {
      store: rootStore.pages.muliBands,
      key: 'muliBands',
      whiteList: [
        'symbol',
        'orderAmount',
        'topMultiplier',
        'bottomMultiplier',
        'strip',
        'movingContainerId',
        'generalTab',
      ],
    },
    {
      store: rootStore.app,
      key: 'app',
      whiteList: ['chartType', 'themeMode', 'designType'],
    },
    {
      store: rootStore.user,
      key: 'user',
      whiteList: ['portfolio', 'profile', 'settings', 'tradingAccount'],
    },
    { store: rootStore.ui, key: 'ui', whiteList: ['language', 'sidebar'] },
    {
      store: rootStore.filters,
      key: 'filters',
    },
    {
      store: rootStore.auth,
      key: 'auth',
      whiteList: ['userActivity', 'isAuth', 'isAuthChecked'],
    },
  ];

  createPersist(storesPersist);

  storesPersist.forEach(({ store, key }) => {
    if (store) {
      rehydrate(store, key);
    }
  });
};
