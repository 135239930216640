import React, { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Modals, TopBar, LeftBar, BottomBar } from '@trader/containers';
import { useInstruments, useWindowDimensions } from '@trader/hooks';
import { breakpoints } from '@trader/themes';
import { cfdTabs } from '@trader/constants';
import { useMst } from '@trader/store';

import * as Styled from './styled';

export const TradingLayout: React.FC = observer(() => {
  const store = useMst();
  const { width } = useWindowDimensions();
  const symbol = store.pages.trading.getInstrumentSymbolByLayout();

  const breakpointSm = breakpoints?.values?.sm as number;
  const shouldDisplay = width > breakpointSm;

  useInstruments([symbol], true);

  return (
    <Fragment>
      <Styled.Root>
        <TopBar />
        <Styled.Main $isSidebarOpen>
          <Styled.Body>
            {shouldDisplay && <LeftBar tabsList={cfdTabs} />}
            <Styled.Routing>
              <Outlet />
            </Styled.Routing>
          </Styled.Body>
        </Styled.Main>
        <BottomBar />
      </Styled.Root>
      <Modals />
    </Fragment>
  );
});
