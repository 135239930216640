import { useEffect } from 'react';

import { useMst } from '@trader/store';

import { useHub } from './core/useHub';
import { EConnectionHub, EConnectionHubSubscriber } from './core/types';

export const usePriceAlertsCreation = () => {
  const store = useMst();

  const idToken = store.auth.tokens.idToken || '';

  const { unsubscribe, subscribe } = useHub<{ id: number }>({
    url: '/inboxes',
    hub: EConnectionHub.Inboxes,
    subscriber: EConnectionHubSubscriber.PriceAlertsCreation,
    invokedName: 'OnAlertStarted',
    onMessage: message => {
      const createdPriceAlertId = message.id;
      store.entities.alerts.getAlertByIdAsync.run(createdPriceAlertId);
    },
  });

  useEffect(() => {
    if (store.auth.isAuth) {
      subscribe();
    }
    return () => {
      if (!store.auth.isAuth) {
        unsubscribe();
      }
    };
  }, [store.auth.isAuth, idToken]);
};
