import { NavigateFunction } from 'react-router-dom';
import {
  api,
  IAccountStatementBE,
  IGetAccountStatementParams,
} from '@trader/api';
import {
  defaultSubCategoryId,
  isChallengeAccountType,
  LOCAL_STORAGE_KEYS,
  MODAL_TYPES,
  NAVIGATE_TO,
} from '@trader/constants';
import { devLoggerService, localStorageService } from '@trader/services';
import { EChallengeStatus, EChartLayoutTypes } from '@trader/types';
import { returnTypedThis } from '@trader/utils';

import { getRootInstance } from '../../configureStore/configureStore';
import { TCategoryEntity } from '../../entities/categories';
import { createThunk } from '../../utils/asyncModel';
import { TTradingStore } from './index';
import { TTradingAccountEntity } from '@trader/store';

interface IParams {
  navigation?: NavigateFunction;
  isFetchTradingAccount?: boolean;
}

export const getPrefetchInformationAsync = createThunk<IParams, void>(
  ({ navigation, isFetchTradingAccount = true }) =>
    async function getPrefetchInformation(this: unknown, _options, _flow) {
      const root = getRootInstance();
      const that = returnTypedThis<TTradingStore>(this);

      try {
        isFetchTradingAccount &&
          (await root.entities.tradingAccounts.getTradingAccountsAsync.run());

        const accounts =
          root.entities.tradingAccounts.getAll<TTradingAccountEntity>();

        if (!accounts?.length) {
          root.ui.modal.open(MODAL_TYPES.resultChallenge, {
            isActiveChallenge: false,
            shouldHideCloseButton: true,
            result: 'EmptyAccounts',
          });
          return;
        }

        isFetchTradingAccount &&
          isChallengeAccountType &&
          (await root.entities.challenges.getChallengesAsync.run());

        await root.entities.categories.getCategoriesAsync.run();

        const selectedCategory = that.selectedCategory;

        if (!selectedCategory) {
          return;
        }

        await root.entities.instruments.getInstrumentsAsync.run({
          shouldClearBeforeMerge: !isFetchTradingAccount,
        });

        const activeChallenge = root.user.getActiveAccountChallenge();

        if (activeChallenge?.status === EChallengeStatus.Disable) {
          navigation &&
            navigation(NAVIGATE_TO.main.myAccount, {
              state: {
                disableMultipleLayouts: true,
                disableTradingNavigation: true,
              },
            });
        }
      } catch (e) {
        devLoggerService.error('Error in getPrefetchInformationAsync', e);
      } finally {
        const accounts =
          root.entities.tradingAccounts.getAll<TTradingAccountEntity>();

        if (accounts.length) {
          isFetchTradingAccount && (await root.user.getSettingsAsync.run());
          await root.user.getProfileDemoAsync.run();
        }
      }
    }
);

export const selectCategoryAsync = createThunk<string, void>(
  categoryName =>
    async function selectCategory(this: unknown, _options, _flow) {
      try {
        localStorageService.set(LOCAL_STORAGE_KEYS.category, categoryName);
        const root = getRootInstance();
        const that = returnTypedThis<TTradingStore>(this);
        const isSingleLayout =
          root.pages.trading.layout.layoutType === EChartLayoutTypes.Single;

        const category = root.entities.categories
          .getAll<TCategoryEntity>()
          .find(c => c.name === categoryName);

        if (!category) {
          return;
        }

        that.runInAction(() => {
          that.selectedCategory = category;
          that.selectedSubCategory = defaultSubCategoryId;
        });

        const response =
          await root.entities.instruments.getInstrumentsAsync.run({
            shouldClearBeforeMerge: true,
          });

        const symbolResponse = response?.instruments?.length
          ? response?.instruments[0]?.symbol
          : '';

        isSingleLayout && that.layout.selectInstrument(symbolResponse);
      } catch (e) {
        devLoggerService.error('Error in selectCategoryAsync', e);
      }
    }
);

export const getAccountStatementAsync = createThunk<
  IGetAccountStatementParams,
  IAccountStatementBE
>(
  ({ from, to, platformLogin }) =>
    async function getAccountStatement(this: unknown, _options, _flow) {
      return await api.Trading.getAccountStatement(
        { from, to, platformLogin },
        _options
      );
    }
);
