import styled, { css } from 'styled-components';
import { Wrapper } from '@trader/components';
import { layoutChildrenRadius, layoutMargin } from '@trader/constants';
import { conditionApply } from '@trader/utils';

export const Root = styled(Wrapper)<{
  $width?: number;
  $minWidth?: number;
  $maxWidth?: number;
  $height?: number;
  $minHeight?: number;
  $maxHeight?: number;
}>`
  ${({
    theme,
    $width,
    $minWidth,
    $maxWidth,
    $height,
    $minHeight,
    $maxHeight,
  }) => css`
    width: 100%;
    background: ${theme.palette.background.paper};
    border-radius: ${layoutChildrenRadius}px;
    flex-direction: column;
    height: inherit;
    flex-shrink: 0;
    margin-right: ${layoutMargin}px;
    position: relative; // Ensure the resize handle is positioned correctly
    ${conditionApply(
      [$minWidth, $maxWidth, $width],
      css`
        min-width: ${$minWidth}px;
        max-width: ${$maxWidth}px;
        width: ${$width}px;
      `
    )};

    ${conditionApply(
      [$height, $minHeight, $maxHeight],
      css`
        min-height: ${$minHeight}px;
        max-height: ${$maxHeight}px;
        height: ${$height}px;
      `
    )};
  `};
`;

export const ResizeHandler = styled.div<{ $isExpandedHeight: boolean }>`
  ${({ $isExpandedHeight }) => css`
    width: 5px;
    cursor: ew-resize;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    ${conditionApply(
      [$isExpandedHeight],
      css`
        cursor: row-resize;
        width: 100%;
        height: 5px;
      `
    )};
  `}
`;
