import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';

import { IAccountStatementBE } from '@trader/api';
import { appConfigUtils, dateFormats } from '@trader/utils';
import { imageGenerationService, useI18next } from '@trader/services';
import { useMst } from '@trader/store';

import {
  balanceHistoryPositionsActions,
  formatDecimal,
  getAccountStatementActionDescription,
} from '../../utils';
import { styles } from './styled';
import { IAccountStatementHtmlReport } from '../htmlReport';

export interface IAccountStatementPdfReport
  extends IAccountStatementHtmlReport {
  logo;
}

export const PdfReport: React.FC<IAccountStatementPdfReport> = observer(
  ({
    from,
    to,
    platformLogin,
    currency,
    product,
    accountType,
    accountStatement,
    logo,
  }) => {
    const store = useMst();

    return (
      <Document style={styles.page}>
        <Page size='A4' orientation='landscape' style={styles.page}>
          <View style={styles.section}>
            <View style={styles.column}>
              <Header logo={logo} />
              <Title from={from} to={to} />
              <AccountInfo
                lastName={store.user.profile.lastName || ''}
                firstName={store.user.profile.firstName || ''}
                accountType={accountType}
                product={product}
                platformLogin={platformLogin}
                brand={appConfigUtils.getCurrentBrand()}
                currency={currency}
              />
              <Summary accountStatement={accountStatement} />
              <CostOverview accountStatement={accountStatement} />
              <Chart />
              <Positions accountStatement={accountStatement} />
              <Deals accountStatement={accountStatement} />
              <Results
                accountStatement={accountStatement}
                currency={currency}
              />
            </View>
          </View>
        </Page>
      </Document>
    );
  }
);

const TableData = ({ label, value, viewStyles, labelStyles, valueStyles }) => (
  <View style={viewStyles}>
    <Text style={labelStyles}>{label}</Text>
    <Text style={valueStyles}>{value}</Text>
  </View>
);

const Header = ({ logo }) => {
  return (
    <>
      <View style={styles.row}>
        <View style={styles.col3}>
          <View style={styles.column_MT_10}>{logo}</View>
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Title = ({ from, to }: { from: string; to: string }) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h1}>
          {translate('COMMON.LABELS.TRADE_HISTORY_REPORT')}
        </Text>
        <Text style={styles.b}>
          {dateFormats(new Date(from), 'dd.mm.yyyy')} -{' '}
          {dateFormats(new Date(to), 'dd.mm.yyyy')}
        </Text>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const AccountInfo = ({
  firstName,
  lastName,
  platformLogin,
  currency,
  product,
  accountType,
  brand,
}: {
  firstName: string;
  lastName: string;
  platformLogin: string;
  currency: string;
  product: string;
  accountType: string;
  brand: string;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <View style={styles.row}>
          <View style={styles.col9}>
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.NAME')}
              value={`${firstName} ${lastName}`}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.ACCOUNT')}
              value={`${platformLogin} (${currency}, ${product}, ${accountType})`}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.COMPANY')}
              value={brand}
            />
            <TableData
              viewStyles={styles.tr}
              labelStyles={styles.label50}
              valueStyles={styles.value}
              label={translate('COMMON.LABELS.DATE')}
              value={dateFormats(new Date(), 'dd.mm.yyyy hh:mm:ss')}
            />
          </View>
        </View>
      </View>
      <View style={{ ...styles.divider, margin: '5px 0 10px 0' }} />
    </>
  );
};

const Summary = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.SUMMARY')}</Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.BALANCE')}
            value={formatDecimal(accountStatement.summary.balance)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.USED_MARGIN')}
            value={formatDecimal(accountStatement.summary.usedMargin)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.DEPOSIT')}
            value={formatDecimal(accountStatement.summary.deposit)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.EQUITY')}
            value={formatDecimal(accountStatement.summary.equity)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.USABLE_MARGIN')}
            value={formatDecimal(accountStatement.summary.marginFree?.value)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.WITHDRAWAL')}
            value={formatDecimal(accountStatement.summary.withdrawal)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.NET_PL')}
            value={formatDecimal(accountStatement.summary.grossPl)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={`${translate('COMMON.LABELS.USABLE_MARGIN')}, %`}
            value={`${formatDecimal(
              accountStatement.summary.marginFree?.percent
            )}%`}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.ADJUSTMENT')}
            value={formatDecimal(accountStatement.summary.adjustment)}
          />
        </View>
        <View style={styles.tr}>
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.REALIZED_TOTAL_PL')}
            value={formatDecimal(accountStatement.summary.realizedPl)}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={`${translate('COMMON.LABELS.EQUITY_MC_LEVEL')}, %`}
            value={`${formatDecimal(accountStatement.summary.mcLevel)}%`}
          />
          <TableData
            viewStyles={styles.col4}
            labelStyles={styles.label}
            valueStyles={styles.value}
            label={translate('COMMON.LABELS.NET')}
            value={formatDecimal(accountStatement.summary.net)}
          />
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const CostOverview = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>
          {translate('COMMON.LABELS.COSTS_OVERVIEW')}
        </Text>
      </View>
      <View style={styles.column_MT_10}>
        <Text style={styles.h3}>
          {translate('COMMON.LABELS.TRADING_COSTS')}
        </Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.col11}>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text></Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.ACTIVE_TRADING_COSTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.CLOSED_TRADING_COSTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.TOTAL_TRADING_COSTS')}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.OPEN_COMMISSIONS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.openCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.openCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.openCommissions
                )}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.CLOSE_COMMISSIONS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading
                    ?.closeCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.closeCommissions
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.closeCommissions
                )}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col3}>
              <Text style={styles.b}>{translate('COMMON.LABELS.SPREAD')}</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeTrading?.spread
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeTrading?.spread
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalTrading?.spread
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.column_MT_10}>
        <Text style={styles.h3}>
          {translate('COMMON.LABELS.OTHER_ADJUSTMENTS')}
        </Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.col11}>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text></Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.ACTIVE_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.CLOSED_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.bRight}>
                {translate('COMMON.LABELS.TOTAL_OTHER_ADJUSTMENTS')}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.OVERNIGHT_SWAP')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.swap
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.swap
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.swap
                )}
              </Text>
            </View>
          </View>
          <View style={styles.rowMB5}>
            <View style={styles.col3}>
              <Text style={styles.b}>
                {translate('COMMON.LABELS.DIVIDENDS')}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.dividends
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.dividends
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.dividends
                )}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.col3}>
              <Text style={styles.b}>{translate('COMMON.LABELS.WHT')}</Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.activeAdjustments?.wht
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.closeAdjustments?.wht
                )}
              </Text>
            </View>
            <View style={styles.col3}>
              <Text style={styles.spanRight}>
                {formatDecimal(
                  accountStatement.costsOverview?.totalAdjustments?.wht
                )}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Chart = () => {
  const { translate } = useI18next();
  const [balanceChart, setBalanceChart] = useState('');
  const [chartGenerationCounter, setChartGenerationCounter] = useState(0);
  const intervalRef = useRef<undefined | number>(undefined);

  const generateBalanceChart = async () => {
    const chartEl = document.querySelector('.recharts-responsive-container');
    if (chartEl) {
      const chart = await imageGenerationService.fromHtmlToDataUrl({
        element: chartEl as HTMLElement,
      });
      setBalanceChart(chart);
      setChartGenerationCounter(prev => prev + 1);
    }
  };

  // Generate the chart up to 2 times to be sure
  // that we have up-to-date chart for downloading.
  const generationTimes = 2;
  const intervalTime = 1000;
  useEffect(() => {
    if (chartGenerationCounter <= generationTimes && !intervalRef.current) {
      intervalRef.current = window.setInterval(
        generateBalanceChart,
        intervalTime
      );
    }

    if (chartGenerationCounter >= generationTimes) {
      window.clearInterval(intervalRef.current);
    }
  }, [chartGenerationCounter]);

  if (!balanceChart) {
    return null;
  }

  return (
    <>
      <View style={{ ...styles.divider, margin: '48px 0 15px 0' }} />
      <View style={styles.column}>
        <View style={styles.column}>
          <Text style={{ ...styles.h2, marginBottom: '10px' }}>
            {translate('COMMON.LABELS.BALANCE_CHART')}
          </Text>
        </View>
        <Image src={balanceChart} style={{ width: '100%', height: '120px' }} />
      </View>
      <View style={{ ...styles.divider, margin: '-5px 0 15px 0' }} />
    </>
  );
};

const Positions = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.POSITIONS')}</Text>
      </View>
      <View style={styles.column_MT_10}>
        {accountStatement.openPositions.length > 0 ? (
          <View style={styles.column}>
            <View style={styles.rowMB5}>
              <View style={styles.colPositions}>
                <View style={styles.row}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.POSITION_ID')}
                  </Text>
                </View>
              </View>
              <View style={styles.col9_100}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.ENTRY_TIME')}
                  </Text>
                </View>
              </View>
              <View style={styles.col8_100}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SYMBOL')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.DIRECTION')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.QUANTITY')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.ENTRY_PRICE')}
                  </Text>
                </View>
              </View>
              <View style={styles.col6_100}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.STOP_LOSS')}
                  </Text>
                </View>
              </View>
              <View style={styles.col6_100}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.TAKE_PROFIT')}
                  </Text>
                </View>
              </View>
              <View style={styles.col6_100}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SWAP')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.OPEN_COMMISSION')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.CLOSE_COMMISSION')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.PRICE')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.FEE')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SPREAD')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.NET_PL')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.COMMENT')}
                  </Text>
                </View>
              </View>
            </View>
            {accountStatement.openPositions.map(t => (
              <View style={styles.rowMB5}>
                <View style={styles.colPositions}>
                  <View style={styles.row}>
                    <Text style={styles.span8}>{t.positionId}</Text>
                  </View>
                </View>
                <View style={styles.col9_100}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>
                      {dateFormats(
                        new Date(t.entryTime as unknown as string),
                        'dd.mm.yyyy hh:mm'
                      )}
                    </Text>
                  </View>
                </View>
                <View style={styles.col8_100}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.symbol}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.direction}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.quantity}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.entryPrice}</Text>
                  </View>
                </View>
                <View style={styles.col6_100}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.stopLoss}</Text>
                  </View>
                </View>
                <View style={styles.col6_100}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.takeProfit}</Text>
                  </View>
                </View>
                <View style={styles.col6_100}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.swap}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.openCommission}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.closeCommission}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.price}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.fee}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.spread}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.pl}</Text>
                  </View>
                </View>
                <View style={styles.colPositions}>
                  <View style={styles.rowCenter}>
                    <Text style={styles.span8}>{t.comment}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.span}>
            {translate('COMMON.LABELS.NO_TRANSACTIONS')}
          </Text>
        )}
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Deals = ({
  accountStatement,
}: {
  accountStatement: IAccountStatementBE;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.DEALS')}</Text>
      </View>
      <View style={styles.column_MT_10}>
        {accountStatement.balanceHistory.length > 0 ? (
          <View style={styles.column}>
            <View style={styles.rowMB5}>
              <View style={styles.colPositions}>
                <View style={styles.row}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.POSITION_ID')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.ENTRY_TIME')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.EXIT_TRANSFER_TIME')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SYMBOL')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.DIRECTION')}
                  </Text>
                </View>
              </View>
              <View style={styles.colPositions}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.QUANTITY')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.ENTRY_PRICE')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.EXIT_PRICE')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.FEE')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.COMMISSION')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SWAP')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.SPREAD')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.PROFIT_LOSS_BALANCE')}
                  </Text>
                </View>
              </View>
              <View style={styles.col}>
                <View style={styles.rowCenter}>
                  <Text style={styles.b8}>
                    {translate('COMMON.LABELS.COMMENT')}
                  </Text>
                </View>
              </View>
            </View>
            {accountStatement.balanceHistory.map(t => (
              <View style={styles.row}>
                {balanceHistoryPositionsActions.includes(t.action) ? (
                  <View style={styles.rowMB5}>
                    <View style={styles.colPositions}>
                      <View style={styles.row}>
                        <Text style={styles.span8}>{t.positionId}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>
                          {dateFormats(
                            new Date(t.entryTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>
                          {dateFormats(
                            new Date(t.exitTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.colPositions}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.symbol}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>
                          {getAccountStatementActionDescription(t.action)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.colPositions}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.quantity}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.entryPrice}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.exitPrice}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.fee}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.commission}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.swap}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.spread}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.pl}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8}>{t.comment}</Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={styles.rowMB5}>
                    <View style={styles.colPositions}>
                      <View style={styles.row}>
                        <Text style={styles.span8}></Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>
                          {dateFormats(
                            new Date(t.entryTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>
                          {dateFormats(
                            new Date(t.exitTime as unknown as string),
                            'dd.mm.yyyy hh:mm'
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.colPositions}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>{t.symbol}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>
                          {getAccountStatementActionDescription(t.action)}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.colPositions}>
                      <View style={styles.row}>
                        <Text style={styles.span8}></Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.row}>
                        <Text style={styles.span8}></Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.row}>
                        <Text style={styles.span8}></Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>{t.spread}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>{t.pl}</Text>
                      </View>
                    </View>
                    <View style={styles.col}>
                      <View style={styles.rowCenter}>
                        <Text style={styles.span8Italic}>{t.comment}</Text>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            ))}
          </View>
        ) : (
          <Text style={styles.span}>
            {translate('COMMON.LABELS.NO_TRANSACTIONS')}
          </Text>
        )}
      </View>
      <View style={styles.divider} />
    </>
  );
};

const Results = ({
  accountStatement,
  currency,
}: {
  accountStatement: IAccountStatementBE;
  currency: string;
}) => {
  const { translate } = useI18next();

  return (
    <>
      <View style={styles.column}>
        <Text style={styles.h2}>{translate('COMMON.LABELS.RESULTS')}</Text>
      </View>
      <View style={styles.column_MT_5}>
        <View style={styles.row}>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.TOTAL_NET_PROFIT')}
                value={formatDecimal(accountStatement.netProfit)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.PROFIT_FACTOR')}
                value={formatDecimal(accountStatement.profitFactor)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.RECOVERY_FACTOR')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_ABSOLUTE')}
                value={formatDecimal(accountStatement.absoluteDrawdown)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.TOTAL_TRADES')}
                value={accountStatement.totalTrades}
              />
            </View>
          </View>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.GROSS_PROFIT')}
                value={formatDecimal(accountStatement.grossProfit)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.EXPECTED_PAYOFF')}
                value={formatDecimal(accountStatement.expectedPayoff)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.SHARPE_RATIO')}
                value={translate('COMMON.LABELS.N_A')}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_MAXIMAL')}
                value={`${formatDecimal(
                  accountStatement.maxDrawdown.value
                )} (${formatDecimal(accountStatement.maxDrawdown.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.SHORT_TRADES_WON')}
                value={`${accountStatement.shortTrades.value} (${formatDecimal(
                  accountStatement.shortTrades.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.PROFIT_TRADES_OF_TOTAL')}
                value={`${accountStatement.profitTrades.value} (${formatDecimal(
                  accountStatement.profitTrades.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LARGEST_PROFIT_TRADE')}
                value={accountStatement.largestTrade}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_PROFIT_TRADE')}
                value={accountStatement.avgProfitTrade}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_WINS', {
                  currency: currency,
                })}
                value={`${
                  accountStatement.maxConsecutiveProfit
                } (${formatDecimal(accountStatement.maxConsecutiveWins)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMAL_CONSECUTIVE_WIN_COUNT')}
                value={`${accountStatement.maxConsecutiveWins} (${formatDecimal(
                  accountStatement.maxConsecutiveProfit
                )})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_WINS')}
                value={accountStatement.avgConsecutiveWins}
              />
            </View>
          </View>
          <View style={styles.col4}>
            <View style={styles.column}>
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.GROSS_LOSS')}
                value={formatDecimal(accountStatement.grossLoss)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.space}
                valueStyles={styles.space}
                label={'Space'}
                value={'Space'}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.BALANCE_DRAWDOWN_RELATIVE')}
                value={`${formatDecimal(
                  accountStatement.relativeDrawdown.value
                )} (${formatDecimal(
                  accountStatement.relativeDrawdown.percent
                )}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LONG_TRADES_WON')}
                value={`${formatDecimal(
                  accountStatement.longTrades.value
                )} (${formatDecimal(accountStatement.longTrades.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LOSS_TRADES_OF_TOTAL')}
                value={`${formatDecimal(
                  accountStatement.lossTrades.value
                )} (${formatDecimal(accountStatement.lossTrades.percent)}%)`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.LARGEST_LOSS_TRADE')}
                value={formatDecimal(accountStatement.largestLossTrade)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_LOSS_TRADE')}
                value={formatDecimal(accountStatement.avgLossTrade)}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES', {
                  currency: currency,
                })}
                value={`${formatDecimal(
                  accountStatement.maxConsecutiveLoss
                )} (${formatDecimal(accountStatement.maxConsecutiveLosses)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate(
                  'COMMON.LABELS.MAXIMUM_CONSECUTIVE_LOSSES_COUNT'
                )}
                value={`${formatDecimal(
                  accountStatement.maxConsecutiveLosses
                )} (${formatDecimal(accountStatement.maxConsecutiveLoss)})`}
              />
              <TableData
                viewStyles={styles.tr}
                labelStyles={styles.labelRight150}
                valueStyles={styles.value}
                label={translate('COMMON.LABELS.AVERAGE_CONSECUTIVE_LOSSES')}
                value={accountStatement.avgConsecutiveLosses}
              />
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
