import React from 'react';
import { observer } from 'mobx-react-lite';

import { shouldDisplayCreditInPortfolio } from '@trader/constants';
import {
  calcEquity,
  calcFreeMargin,
  calcMarginLevel,
  formatAccountCurrencyValue,
  formatAccountPercentageValue,
} from '@trader/utils';
import { useMst } from '@trader/store';
import { usePositionMetricsPl } from '@trader/hooks';
import { useI18next } from '@trader/services';

import * as Styled from './styled';

interface IBalanceDetails {
  isShowDetails: boolean;
}

export const BalanceDetails: React.FC<IBalanceDetails> = observer(
  ({ isShowDetails }) => {
    const { translate } = useI18next();
    const { positionsPl } = usePositionMetricsPl();

    const store = useMst();
    const portfolio = store.user.portfolio();
    const tradingAccount = store.user.activeTradingAcc();

    const currencySymbol = tradingAccount.currencySymbol;
    const equity = calcEquity(positionsPl, portfolio.balance, portfolio.credit);

    return (
      <Styled.Root $isShowDetails={isShowDetails}>
        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.BALANCE')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(portfolio.balance, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        {shouldDisplayCreditInPortfolio && (
          <Styled.BalanceContainer>
            <Styled.Title>{translate('COMMON.LABELS.CREDIT')}</Styled.Title>
            <Styled.Value>
              {formatAccountCurrencyValue(portfolio.credit, currencySymbol)}
            </Styled.Value>
          </Styled.BalanceContainer>
        )}

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.EQUITY')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(equity, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.P_L')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(positionsPl, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.FREE_MARGIN')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(
              calcFreeMargin(equity, portfolio.usedMargin),
              currencySymbol
            )}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.USED_MARGIN')}</Styled.Title>
          <Styled.Value>
            {formatAccountCurrencyValue(portfolio.usedMargin, currencySymbol)}
          </Styled.Value>
        </Styled.BalanceContainer>

        <Styled.BalanceContainer>
          <Styled.Title>{translate('COMMON.LABELS.MARGIN_LEVEL')}</Styled.Title>
          <Styled.Value>
            {formatAccountPercentageValue(
              calcMarginLevel(equity, portfolio.usedMargin)
            )}
          </Styled.Value>
        </Styled.BalanceContainer>
      </Styled.Root>
    );
  }
);
